import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  notifications: {
    notifications: [],
    current_page: 1,
    per_page: 0,
    total: 0
  },
  unread_count: 0,

  changing: 0,

  loading: false,
  error: {}
}

const fetchNotificationsStart = (state, action) => {
  return updateObject(state, { loading: true})
}

const fetchNotificationsSuccess = (state, action) => {
  return updateObject(state, {
    notifications: action.notifications,
    unread_count: action.unread_count,
    // changing: state.changing+1,
    loading: false
  })
}

const fetchNotificationsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const readNotificationsStart = (state, action) => {
  return updateObject(state, { loading: true})
}

const readNotificationsSuccess = (state, action) => {
  return updateObject(state, {
    changing: state.changing+1,
    loading: false
  })
}

const readNotificationsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_NOTIFICATIONS_START: return fetchNotificationsStart(state, action)
    case actionTypes.FETCH_NOTIFICATIONS_SUCCESS: return fetchNotificationsSuccess(state, action)
    case actionTypes.FETCH_NOTIFICATIONS_FAIL: return fetchNotificationsFail(state, action)
    case actionTypes.READ_NOTIFICATIONS_START: return readNotificationsStart(state, action)
    case actionTypes.READ_NOTIFICATIONS_SUCCESS: return readNotificationsSuccess(state, action)
    case actionTypes.READ_NOTIFICATIONS_FAIL: return readNotificationsFail(state, action)
    default: return state
  }
}

export default reducer