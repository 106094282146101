import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Aux from '../../../hoc/Aux/Aux'

function getModalStyle () {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  }
}

const useStyles = makeStyles((theme) => ({
  modalStyle1: {
    position: 'absolute !important',
    overflow: 'scroll'
    // marginTop: 250
  },
  paper: {
    position: 'absolute',
    margin: '30% 0px 10%',
    width: '90%',
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: '#ecebeb',
    // border: '2px solid #000',
    borderColor: '#ffffff',
    boxShadow: theme.shadows[5],
    borderRadius: 8,
    padding: theme.spacing(2, 4, 3)
  }
}))

const ModalComponent = props => {
  const classes = useStyles()
  const [modalStyle] = React.useState(getModalStyle)

  return (
    <Aux>
      <Modal
        open={props.open}
        onClose={props.modalClosed}
        aria-labelledby="modal-title"
        aria-describedby="modal-body"
        disableScrollLock={true}
        className={classes.modalStyle1}
      >
        <div style={modalStyle} className={classes.paper}>
          {props.children}
        </div>
      </Modal>
    </Aux>
  )
}

export default ModalComponent
