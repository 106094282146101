import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  file: '',
  loading: false,
  error: {},
}

const downloadAssetsStart = (state, action) => {
  return updateObject(state, {
    loading: true
  })
}

const downloadAssetsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    file: action.file
  })
}

const downloadAssetsFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error
  })
}

const downloadQRAssetsStart = (state, action) => {
  return updateObject(state, {
    loading: true
  })
}

const downloadQRAssetsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    file: action.file
  })
}

const downloadQRAssetsFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DOWNLOAD_LAPORAN_ASSETS_START: return downloadAssetsStart(state, action)
    case actionTypes.DOWNLOAD_LAPORAN_ASSETS_SUCCESS: return downloadAssetsSuccess(state, action)
    case actionTypes.DOWNLOAD_LAPORAN_ASSETS_FAIL: return downloadAssetsFail(state, action)
    case actionTypes.DOWNLOAD_LAPORAN_QR_ASSETS_START: return downloadQRAssetsStart(state, action)
    case actionTypes.DOWNLOAD_LAPORAN_QR_ASSETS_SUCCESS: return downloadQRAssetsSuccess(state, action)
    case actionTypes.DOWNLOAD_LAPORAN_QR_ASSETS_FAIL: return downloadQRAssetsFail(state, action)
    default: return state
  }
}

export default reducer