import React, { Fragment } from 'react';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import { options } from './Grafik'
import { Grid, Typography } from '@material-ui/core';

const Chart = props => {

  const { assetDevaluing } = props;

  console.log(assetDevaluing[0].month);
  let data = {};
  let bulan = [];
  let assetValue = [];

  if(assetDevaluing.length > 0){
    // console.log(assetDevaluing.length);
    for (var i = 0; i < assetDevaluing.length; i++) {
      bulan.push(moment(assetDevaluing[i].date).format('MM/YYYY'));
      assetValue.push(assetDevaluing[i].asset_value);
    }

    data = {
        labels: bulan,
        datasets: [
          {
            label : 'Grafik Penyusutan Aset',
            data: assetValue,
            backgroundColor: 'rgba(75,192,192,0.4)',
          }
        ]
    };
  }else{
    data = {
      labels: ["loading"],
      datasets: [
        {
          label : 'Grafik Penyusutan Aset',
          data: ["loading"],
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
        }
      ]
    };
  }

  return (
    <Fragment>
      <Grid
        container
        spacing={2}
      >
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography variant="h4"> Penyusutan Aset </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Line
            width={100}
            height={30}
            data={data}
            options={options}
          />
        </Grid>
      </Grid>
    </Fragment>
  )
};

export default Chart;