import * as actions from './actionTypes'
import axios from '../../axios-orders'
import { v4 as uuid } from 'uuid'
import { setAlert } from './alert'
import fileDownload from 'js-file-download'

export const fetchAsetWakafsStart = () => {
  return {
    type: actions.FETCH_ASET_WAKAF_START
  }
}

export const fetchAsetWakafsSuccess = (assets, current_page, per_page, total) => {
  return {
    type: actions.FETCH_ASET_WAKAF_SUCCESS,
    assets: {
      assets,
      current_page,
      per_page,
      total
    }
  }
}

export const fetchAsetWakafsFail = (error) => {
  return {
    type: actions.FETCH_ASET_WAKAF_FAIL,
    error: error
  }
}

export const fetchAsetWakafs = (page, token, formSearch) => {
  let param = '&id_type='+formSearch.id_type+'&id_cat='+formSearch.id_cat+'&name='+formSearch.name+'&status='+formSearch.status+'&id_entity='+formSearch.id_entity+'&end_date='+formSearch.end_date;
  // let param = '&id_type='+formSearch.id_type+'&id_cat='+formSearch.id_cat+'&name='+formSearch.name+'&end_date='+formSearch.end_date;
  console.log(param)
  return dispatch => {
    dispatch(fetchAsetWakafsStart())
    axios.get('assets?page=' + page + '&sort_by=created_at&order_by=desc' + param, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        const fetchedAsetWakafs = []
        for (const key in res.data.data) {
          fetchedAsetWakafs.push({
            ...res.data.data[key]
          })
        }
        dispatch(fetchAsetWakafsSuccess(fetchedAsetWakafs, res.data.current_page, res.data.per_page, res.data.total))
      })
      .catch(err => {
        dispatch(fetchAsetWakafsFail(err))
      })
  }
}

export const storeAsetWakafStart = () => {
  return {
    type: actions.STORE_ASET_WAKAF_START
  }
}

export const storeAsetWakafSuccess = (isSuccess) => {
  return {
    type: actions.STORE_ASET_WAKAF_SUCCESS,
    isSuccess: isSuccess,
  }
}

export const storeAsetWakafFail = (error) => {
  return {
    type: actions.STORE_ASET_WAKAF_FAIL,
    error: error
  }
}

export const storeAsetWakaf = (storeData, token) => {
  console.log(storeData)
  // const formSearch = {id_type: '', id_cat: '', name: '', end_date: ''}
  return dispatch => {
    dispatch(storeAsetWakafStart())
    axios.post('assets', storeData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        dispatch( storeAsetWakafSuccess( true ) );
        dispatch(setAlert(response.data.msg_str, 'success'))
      })
      .catch(error => {
        dispatch(storeAsetWakafFail(error.response.data.throwable))
        dispatch(setAlert(error.response.data.msg_str, 'error'))
      })
  }
}

export const updateAsetWakafStart = () => {
  return {
    type: actions.UPDATE_ASET_WAKAF_START
  }
}

export const updateAsetWakafSuccess = () => {
  return {
    type: actions.UPDATE_ASET_WAKAF_SUCCESS,
  }
}

export const updateAsetWakafFail = (error) => {
  return {
    type: actions.UPDATE_ASET_WAKAF_FAIL,
    error: error
  }
}

export const updateAsetWakaf = (id, storeData, token, page) => {
  const bodyWakif = { name: storeData.wakifName, phone: storeData.wakifPhone, address: storeData.wakifAddress };
  const formSearch = {id_type: '', id_cat: '', name: '', end_date: '', status:'', id_entity:''}
  return dispatch => {
    dispatch(updateAsetWakafStart())
    axios.put('assets/'+ id, storeData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        
      })
      .catch(error => {
        dispatch(updateAsetWakafFail(error))
      })
    axios.put('assets/wakif/'+ storeData.id_wakif, bodyWakif, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        // dispatch( updateAsetWakafSuccess( response.data.uuid, storeData ) );
        // dispatch(fetchAsetWakafs(1, token, formSearch))
        dispatch( updateAsetWakafSuccess(  ) );
        dispatch(fetchAsetWakafs(page, token, formSearch))
        dispatch(setAlert('Berhasil mengubah aset', 'success'))
      })
      .catch(error => {
        dispatch(updateAsetWakafFail(error))
      })
  }
}

export const deleteAsetWakafStart = () => {
  return {
    type: actions.DELETE_ASET_WAKAF_START
  }
}

export const deleteAsetWakafSuccess = (id) => {
  return {
    type: actions.DELETE_ASET_WAKAF_SUCCESS,
    id: id
    // index: index
  }
}

export const deleteAsetWakafFail = (error) => {
  return {
    type: actions.DELETE_ASET_WAKAF_FAIL,
    error: error
  }
}

export const deleteAsetWakaf = (uuid, token) => {
  const formSearch = {id_type: '', id_cat: '', name: '', end_date: '', status:'', id_entity:''}
  return dispatch => {
    dispatch(deleteAsetWakafStart())
    axios.delete('assets' + uuid, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        dispatch(fetchAsetWakafs(1, token, formSearch))
      })
      .catch(error => {
        dispatch(deleteAsetWakafFail(error))
      })
  }
}

export const searchAsetWakafsStart = () => {
  return {
    type: actions.SEARCH_ASET_WAKAF_START
  }
}

export const searchAsetWakafsSuccess = (assets, current_page, per_page, total) => {
  return {
    type: actions.SEARCH_ASET_WAKAF_SUCCESS,
    assets: {
      assets,
      current_page,
      per_page,
      total
    }
  }
}

export const searchAsetWakafsFail = (error) => {
  return {
    type: actions.SEARCH_ASET_WAKAF_FAIL,
    error: error
  }
}

export const searchAsetWakafs = (page, token, formSearch) => {
  let param = '&id_type='+formSearch.id_type+'&id_cat='+formSearch.id_cat+'&name='+formSearch.name+'&end_date='+formSearch.end_date;
  console.log(param);
  return dispatch => {
    dispatch(searchAsetWakafsStart())
    axios.get('assets?page=' + page +param+ '&sort_by=created_at&order_by=desc', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        const searchedAsetWakafs = []
        for (const key in res.data.data) {
          searchedAsetWakafs.push({
            ...res.data.data[key]
          })
        }
        console.log(res.data.data)
        dispatch(searchAsetWakafsSuccess(searchedAsetWakafs, res.data.current_page, res.data.per_page, res.data.total))
      })
      .catch(err => {
        dispatch(searchAsetWakafsFail(err))
      })
  }
}

export const uploadImageAssetsWakafStart = () => {
  return {
    type: actions.UPLOAD_IMAGES_ASSETS_WAKAF_START
  }
}

export const uploadImageAssetsWakafSuccess = (url, name, message) => {
  const id = uuid()
  return {
    type: actions.UPLOAD_IMAGES_ASSETS_WAKAF_SUCCESS,
    storeId: id,
    url: url,
    name: name,
    message: message 
  }
}

export const uploadImageAssetsWakafFail = (error) => {
  return {
    type: actions.UPLOAD_IMAGES_ASSETS_WAKAF_FAIL,
    error: error
  }
}

export const uploadImageAssetsWakaf = (storeData, token) => {
  return dispatch => {
    dispatch(uploadImageAssetsWakafStart())
    const bodyFormData = new FormData()
    bodyFormData.set('image', storeData)

    axios({
      method: 'post',
      url: 'assets/image/upload',
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        dispatch(uploadImageAssetsWakafSuccess(response.data.url, storeData.name, response.data.msg_str))
      })
      .catch(error => {
        dispatch(uploadImageAssetsWakafFail(error.response.data.throwable.image[0]))
        dispatch(setAlert(error.response.data.throwable.image[0], 'error'))
      })
  }
}

export const deleteImagesAssetsWakafRedux = (index) => {
  return {
    type: actions.DELETE_IMAGES_ASSETS_WAKAF_REDUX,
    index: index
    // index: index
  }
}

export const uploadDocumentAssetsWakafStart = () => {
  return {
    type: actions.UPLOAD_DOCUMENTS_ASSETS_WAKAF_START
  }
}

export const uploadDocumentAssetsWakafSuccess = (url, name, message) => {
  const id = uuid()
  return {
    type: actions.UPLOAD_DOCUMENTS_ASSETS_WAKAF_SUCCESS,
    storeId: id,
    url: url,
    name: name,
    message: message 
  }
}

export const uploadDocumentAssetsWakafFail = (error) => {
  return {
    type: actions.UPLOAD_DOCUMENTS_ASSETS_WAKAF_FAIL,
    error: error
  }
}

export const uploadDocumentAssetsWakaf = (storeData, token) => {
  return dispatch => {
    dispatch(uploadDocumentAssetsWakafStart())
    const bodyFormData = new FormData()
    bodyFormData.set('document', storeData[0])

    axios({
      method: 'post',
      url: 'assets/document/upload',
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        dispatch(uploadDocumentAssetsWakafSuccess(response.data.url,storeData[0].name , response.data.msg_str))
      })
      .catch(error => {
        dispatch(uploadDocumentAssetsWakafFail(error.response.data.msg_str))
        dispatch(setAlert(error.response.data.msg_str, 'error'))
      })
  }
}

export const deleteDocumentAssetsWakafRedux = (index) => {
  return {
    type: actions.DELETE_DOCUMENTS_ASSETS_WAKAF_REDUX,
    index: index
    // index: index
  }
}

export const clearImagesDocumentsAsetWakaf = () => {
  return {
    type: actions.CLEAR_IMAGES_DOCUMENTS_ASET_WAKAF,
  }
}

export const clearErrorFormAsset = () => {
  return {
    type: actions.CLEAR_ERROR_FORM_ASSET,
  }
}

export const deleteImageAssetWakafStart = () => {
  return {
    type: actions.DELETE_IMAGE_ASSET_WAKAF_START
  }
}

export const deleteImageAssetWakafSuccess = (uuid) => {
  return {
    type: actions.DELETE_IMAGE_ASSET_WAKAF_SUCCESS,
    uuid: uuid
  }
}

export const deleteImageAssetWakafFail = (error) => {
  return {
    type: actions.DELETE_IMAGE_ASSET_WAKAF_FAIL,
    error: error
  }
}

export const deleteImageAssetWakaf = (uuid, token) => {
  return dispatch => {
    dispatch(deleteImageAssetWakafStart())
    axios.delete('assets/image/' + uuid, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        dispatch(deleteImageAssetWakafSuccess(uuid))
      })
      .catch(error => {
        dispatch(deleteImageAssetWakafFail(error.response.data.msg_str))
        dispatch(setAlert(error.response.data.msg_str, 'error'))
      })
  }
}

export const deleteDocumentAssetWakafStart = () => {
  return {
    type: actions.DELETE_DOCUMENT_ASSET_WAKAF_START
  }
}

export const deleteDocumentAssetWakafSuccess = (uuid) => {
  return {
    type: actions.DELETE_DOCUMENT_ASSET_WAKAF_SUCCESS,
    uuid: uuid
  }
}

export const deleteDocumentAssetWakafFail = (error) => {
  return {
    type: actions.DELETE_DOCUMENT_ASSET_WAKAF_FAIL,
    error: error
  }
}

export const deleteDocumentAssetWakaf = (uuid, token) => {
  return dispatch => {
    dispatch(deleteDocumentAssetWakafStart())
    axios.delete('assets/document/' + uuid, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        dispatch(deleteDocumentAssetWakafSuccess(uuid))
      })
      .catch(error => {
        dispatch(deleteDocumentAssetWakafFail(error.response.data.msg_str))
        dispatch(setAlert(error.response.data.msg_str, 'error'))
      })
  }
}

export const fetchImageAssetWakafStart = () => {
  return {
    type: actions.FETCH_IMAGE_ASSET_WAKAF_START
  }
}

export const fetchImageAssetWakafSuccess = (images) => {
  return {
    type: actions.FETCH_IMAGE_ASSET_WAKAF_SUCCESS,
    images: images
  }
}

export const fetchImageAssetWakafFail = (error) => {
  return {
    type: actions.FETCH_IMAGE_ASSET_WAKAF_FAIL,
    error: error
  }
}

export const fetchImageAssetWakaf = (uuid, token) => {
  const storeData = { uuid: uuid };
  return dispatch => {
    dispatch(deleteImageAssetWakafStart())
    axios.post('assets/validation', storeData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        dispatch(fetchImageAssetWakafSuccess(response.data.asset.image))
      })
      .catch(error => {
        dispatch(fetchImageAssetWakafFail(error.response.data.msg_str))
        dispatch(setAlert(error.response.data.msg_str, 'error'))
      })
  }
}

export const fetchDocumentAssetWakafStart = () => {
  return {
    type: actions.FETCH_DOCUMENT_ASSET_WAKAF_START
  }
}

export const fetchDocumentAssetWakafSuccess = (documents) => {
  return {
    type: actions.FETCH_DOCUMENT_ASSET_WAKAF_SUCCESS,
    documents: documents
  }
}

export const fetchDocumentAssetWakafFail = (error) => {
  return {
    type: actions.FETCH_DOCUMENT_ASSET_WAKAF_FAIL,
    error: error
  }
}

export const fetchDocumentAssetWakaf = (uuid, token) => {
  // const storeData = { uuid: uuid };
  return dispatch => {
    dispatch(deleteDocumentAssetWakafStart())
    axios.get('assets?uuid=' + uuid, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        // console.log(response.data.data[response.data.total-1].document);
        dispatch(fetchDocumentAssetWakafSuccess(response.data.data[response.data.total-1].document))
      })
      .catch(error => {
        dispatch(fetchDocumentAssetWakafFail(error.response.data.msg_str))
        dispatch(setAlert(error.response.data.msg_str, 'error'))
      })
  }
}

export const clearImagesAssetWakaf = () => {
  return {
    type: actions.CLEAR_IMAGES_ASSET_WAKAF
  }
}

export const clearDocumentsAssetWakaf = () => {
  return {
    type: actions.CLEAR_DOCUMENTS_ASSET_WAKAF
  }
}

export const addImageAssetWakafStart = () => {
  return {
    type: actions.ADD_IMAGE_ASSEET_WAKAF_START
  }
}

export const addImageAssetWakafSuccess = (images) => {
  return {
    type: actions.ADD_IMAGE_ASSEET_WAKAF_SUCCESS,
    images: images
  }
}

export const addImageAssetWakafFail = (error) => {
  return {
    type: actions.ADD_IMAGE_ASSEET_WAKAF_FAIL,
    error: error
  }
}

export const addImageAssetWakafToUUID = (uuid, caption, urlImage, token) => {
  return dispatch => {
    const body = { uuid: uuid, url: urlImage, caption: caption }
    axios.post('assets/image', body, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        dispatch(fetchImageAssetWakaf(uuid, token))
      })
      .catch(error => {
        dispatch(uploadDocumentAssetsWakafFail(error.response.data.msg_str))
        dispatch(setAlert(error.response.data.msg_str, 'error'))
      })
  }
}

export const addImageAssetWakaf = (storeData, uuid, token) => {
  // console.log(storeData[0])
  return dispatch => {
    dispatch(addImageAssetWakafStart());
    const bodyFormData = new FormData()
    bodyFormData.set('image', storeData)

    axios({
      method: 'post',
      url: 'assets/image/upload',
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        dispatch(addImageAssetWakafToUUID(uuid, storeData.name, response.data.url, token));
      })
      .catch(error => {
        dispatch(uploadDocumentAssetsWakafFail(error.response.data.msg_str))
        dispatch(setAlert(error.response.data.msg_str, 'error'))
      })
  }
}

export const addDocumentAssetWakafStart = () => {
  return {
    type: actions.ADD_DOCUMENT_ASSEET_WAKAF_START
  }
}

export const addDocumentAssetWakafSuccess = (images) => {
  return {
    type: actions.ADD_DOCUMENT_ASSEET_WAKAF_SUCCESS,
    images: images
  }
}

export const addDocumentAssetWakafFail = (error) => {
  return {
    type: actions.ADD_DOCUMENT_ASSEET_WAKAF_FAIL,
    error: error
  }
}

export const addDocumentAssetWakafToUUID = (uuid, caption, urlDocument, token) => {
  return dispatch => {
    console.log('reponses',urlDocument)
    const body = { uuid: uuid, url: urlDocument, caption: caption }
    axios.post('assets/document', body, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        dispatch(fetchDocumentAssetWakaf(uuid, token))
      })
      .catch(error => {
        dispatch(uploadDocumentAssetsWakafFail(error.response.data.msg_str))
        dispatch(setAlert(error.response.data.msg_str, 'error'))
      })
  }
}

export const addDocumentAssetWakaf = (storeData, uuid, token) => {
  console.log(storeData[0])
  return dispatch => {
    dispatch(addDocumentAssetWakafStart());
    const bodyFormData = new FormData()
    bodyFormData.set('document', storeData[0])

    axios({
      method: 'post',
      url: 'assets/document/upload',
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        dispatch(addDocumentAssetWakafToUUID(uuid, storeData[0].name, response.data.url, token));
      })
      .catch(error => {
        dispatch(uploadDocumentAssetsWakafFail(error.response.data.msg_str))
        dispatch(setAlert(error.response.data.msg_str, 'error'))
      })
  }
}

export const fetchAssetDepreciationStart = () => {
  return {
    type: actions.FETCH_ASSET_DEPRECIATION_START
  }
}

export const fetchAssetDepreciationSuccess = (asset, devaluing) => {
  return {
    type: actions.FETCH_ASSET_DEPRECIATION_SUCCESS,
    asset: asset,
    devaluing: devaluing,
  }
}

export const fetchAssetDepreciationFail = (error) => {
  return {
    type: actions.FETCH_ASSET_DEPRECIATION_FAIL,
    error: error
  }
}

export const fetchAssetDepreciation = (uuid, token) => {
  return dispatch => {
    dispatch(fetchAssetDepreciationStart())
    axios.get('assets/depreciation/' + uuid, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        // console.log(response.data.data[response.data.total-1].document);
        dispatch(fetchAssetDepreciationSuccess(response.data.asset, response.data.devaluing))
      })
      .catch(error => {
        dispatch(fetchAssetDepreciationFail(error.response.data.msg_str))
        // dispatch(setAlert(error.response.data.msg_str, 'error'))
      })
  }
}

export const downloadAssetStart = () => {
  return {
    type: actions.DOWNLOAD_ASSET_START
  }
}

export const downloadAssetSuccess = (file) => {
  return {
    type: actions.DOWNLOAD_ASSET_SUCCESS,
    file: file
  }
}

export const downloadAssetFail = (error) => {
  return {
    type: actions.DOWNLOAD_ASSET_FAIL,
    error: error
  }
}

export const downloadAsset = (token) => {
  return dispatch => {
    dispatch(downloadAssetStart());
    axios.get('assets/report/bulkqr', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      },
      responseType: 'blob'
    })
      .then(response => {
        fileDownload(response.data, 'download.pdf')
        dispatch(downloadAssetSuccess(response.data))
      })
      .catch(error => {
        console.log(error)
        dispatch(downloadAssetFail(error.response))
        // dispatch(setAlert(error.response.data.msg_str, 'error'))
      })
  }
}