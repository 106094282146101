import React, { Fragment } from 'react';
import { Paper, Table, TableCell, TableContainer, TableHead, TableRow, Typography, makeStyles, TableBody, TableFooter, 
  TablePagination } from '@material-ui/core';

import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import Notification from './Notification/Notification';
import TablePaginationActions from '../../../UI/Table/TablePagination/TablePaginationActions';
// import Category from './Category/Category';

const useStyle = makeStyles(theme => ({
  table: {
    minWidth: 500
  },
  topTable: {
    padding: 16,
    backgroundColor: '#FAFAFA'
  }
}))

const columns = [
  { id: 'judul', label: 'Judul' },
  { id: 'deskripsi', label: 'Deskripsi' },
  { id: 'tanggal', label: 'Tanggal' },
  { id: 'action', label: 'Action', align: 'right' },
]

const NotificationList = props => {
  const { notifications, token, pageNotif, onFetchNotif } = props;

  const classes = useStyle();

  let tableBody = '';
  
  function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

  const handleChangePage = (event, newPage) => {
    onFetchNotif(newPage, token)
  }

  console.log(notifications.notifications)

  if(!isEmpty(notifications)){
    const page = pageNotif - 1
    const rowsPerPage = notifications.per_page
    const countRows = notifications.total
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, countRows - page * rowsPerPage)

    tableBody = (
      <Fragment>
        {/* {loadingNotif} */}
        <TableBody>
          {notifications.notifications.map((notif, index) => (
            <Notification
              notif={notif}
              key={notif.id_notification}
              index={index}
              show={() => props.show(notif)}
            />
          ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[notifications.per_page]}
              colSpan={5}
              count={countRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <TableContainer component={Paper}>
        <div className={classes.topTable}>
          <Typography variant="h4">List Notification</Typography>
        </div>
        <hr />
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {tableBody}
        </Table>
      </TableContainer>
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    notifications: state.notification.notifications,
    loading: state.notification.loading,
    error: state.notification.error,
    token: state.auth.token,
    pageNotif: state.notification.notifications.current_page,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchNotif: (page, token) => dispatch(actions.fetchNotifications(page, token))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList);