import React from 'react';

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { CheckCircleOutlineRounded, Cancel, Autorenew, ReportOffRounded, BlockRounded, Money } from '@material-ui/icons'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles({
  content: {
    paddingTop: 20
  },
  root: {
    minWidth: 275,
    borderRadius: 8,
    color: '#231E2E'
  },
  icon: {
    textAlign: 'right'
  },
  title: {
    fontSize: 18,
    paddingBottom: 15,
    fontFamily: 'Nunito Sans, Roboto, sans-serif'
  },
  value: {
    // fontSize: 30,
    color: '#FF9300'
  },
  valueSum: {
    color: '#828492'
  },
  hrStyle: {
    border: 'none',
    backgroundColor: '#dad6d6',
    height: '1px'
  } 
})

const StatusAset = props => {
  const { dashboardData } = props;

  const classes = useStyles()
  
  console.log(dashboardData);
  return (
    <div className={classes.content}>
      <Card className={classes.root} variant="outlined">
        <CardContent>
          
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography className={classes.title} gutterBottom> Pengelolaan Aset </Typography>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item lg={11} md={11} sm={11} xs={11}>
                <Typography variant="h5"  className={classes.valueSum}> Total Aset </Typography>
              </Grid>
              <Grid item lg={1} md={1} sm={1} xs={1}>
                <Typography variant="h6" className={classes.value}> {dashboardData.all_asset_count} </Typography>
              </Grid>
            </Grid>
            <hr className={classes.hrStyle}/>
            <Grid container>
              <Grid item lg={1} md={1} sm={1} xs={1}>
                {/* <Typography variant="h5"> T </Typography> */}
                <CheckCircleOutlineRounded style={{ color: '#4AD991'}}/>
              </Grid>
              <Grid item lg={10} md={10} sm={10} xs={10}>
                <Typography variant="h5"> Aset Aktif </Typography>
              </Grid>
              <Grid item lg={1} md={1} sm={1} xs={1}>
                <Typography variant="h6" className={classes.valueSum}> {dashboardData.status_asset_count.active} </Typography>
              </Grid>
            </Grid>

            <hr className={classes.hrStyle}/>
            <Grid container>
              <Grid item lg={1} md={1} sm={1} xs={1}>
                <Cancel style={{color: '#B4B4C6'}}/>
              </Grid>
              <Grid item lg={10} md={10} sm={10} xs={10}>
                <Typography variant="h5"> Aset Tidak Aktif </Typography>
              </Grid>
              <Grid item lg={1} md={1} sm={1} xs={1}>
                <Typography variant="h6" className={classes.valueSum}> {dashboardData.status_asset_count.not_active} </Typography>
              </Grid>
            </Grid>

            <hr className={classes.hrStyle}/>
            <Grid container>
              <Grid item lg={1} md={1} sm={1} xs={1}>
                <Autorenew style={{color:'#f7f13d'}}/>
              </Grid>
              <Grid item lg={10} md={10} sm={10} xs={10}>
                <Typography variant="h5"> Perbaikan </Typography>
              </Grid>
              <Grid item lg={1} md={1} sm={1} xs={1}>
                <Typography variant="h6" className={classes.valueSum}> {dashboardData.status_asset_count.maintenance} </Typography>
              </Grid>
            </Grid>

            <hr className={classes.hrStyle}/>
            <Grid container>
              <Grid item lg={1} md={1} sm={1} xs={1}>
                <ReportOffRounded style={{color: '#f73d3d'}}/>
              </Grid>
              <Grid item lg={10} md={10} sm={10} xs={10}>
                <Typography variant="h5"> Rusak </Typography>
              </Grid>
              <Grid item lg={1} md={1} sm={1} xs={1}>
                <Typography variant="h6" className={classes.valueSum}> {dashboardData.status_asset_count.broken} </Typography>
              </Grid>
            </Grid>

            <hr className={classes.hrStyle}/>
            <Grid container>
              <Grid item lg={1} md={1} sm={1} xs={1}>
                <BlockRounded />
              </Grid>
              <Grid item lg={10} md={10} sm={10} xs={10}>
                <Typography variant="h5"> Hilang </Typography>
              </Grid>
              <Grid item lg={1} md={1} sm={1} xs={1}>
                <Typography variant="h6" className={classes.valueSum}> {dashboardData.status_asset_count.lost} </Typography>
              </Grid>
            </Grid>

            <hr className={classes.hrStyle}/>
            <Grid container>
              <Grid item lg={1} md={1} sm={1} xs={1}>
                <Money style={{color: '#8280FF'}}/>
              </Grid>
              <Grid item lg={10} md={10} sm={10} xs={10}>
                <Typography variant="h5"> Dijual </Typography>
              </Grid>
              <Grid item lg={1} md={1} sm={1} xs={1}>
                <Typography variant="h6" className={classes.valueSum}> {dashboardData.status_asset_count.sold} </Typography>
              </Grid>
            </Grid>
          
          <Typography className={classes.valueSum}>
            {/* {totalAset} */}
          </Typography>
        </CardContent>
      </Card>
    </div>
  )
}

export default StatusAset;