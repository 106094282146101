import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../../store/actions/index';

import { Paper, Table, TableCell, TableContainer, TableHead, TableRow, Typography, makeStyles, TableBody, TableFooter, 
  TablePagination, 
  Grid,
  FormControl,
  InputLabel,
  Select,
  InputBase,
  IconButton,
  Divider} from '@material-ui/core';
import TablePaginationActions from '../../../UI/Table/TablePagination/TablePaginationActions';
import AsetWakaf from './AsetWakaf/AsetWakaf';
import { Search } from '@material-ui/icons';

const useStyle = makeStyles(theme => ({
  table: {
    minWidth: 500
  },
  topTable: {
    padding: 16,
    backgroundColor: '#FAFAFA'
  },
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'auto'
  // marginTop: theme.spacing(2)
  },
  searchSelectRoot: {
    // padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'auto'
  // marginTop: theme.spacing(2)
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10,
    backgroundColor: '#FF9300',
    '&:hover': {
      backgroundColor: '#FF9300'
    },
    color: '#FFFFFF',
    borderRadius: 0
  },
  divider: {
    height: 28,
    margin: 4
  },
  formRoot: {
    // border: '1px groove #000',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2, 4, 3)
  }
}))

const columns = [
  { id: 'nama_aset', label: 'Nama Aset' },
  { id: 'jenis', label: 'Jenis' },
  { id: 'kategori', label: 'Kategori' },
  { id: 'pemberi_aset', label: 'Pemberi Aset'},
  { id: 'pengguna', label: 'Pengguna'},
  { id: 'Bagian', label: 'Bagian'},
  { id: 'status', label: 'Status'},
  // { id: 'nilai_perolehan', label: 'Nilai Perolehan' },
  { id: 'action', label: 'Action', align: 'right' }
]

const ListAsetWakaf = props => {
  const classes = useStyle();
  const {assets, token, onFetchTypeAsset, onFetchCategory, typeAssets, categories,onFetchAsset,changing, entities, onFetchEntity} = props;
  const [formSearch, setFormSearch] = useState({
    id_type: '',
    id_cat: '',
    name: '',
    end_date: '',
    status: '',
    id_entity: '',
  })
  let tableBody = '';
  let optionType = '';
  let optionCategories = '';
  let optionEntities = '';

  useEffect(() => {
    onFetchAsset(1, token, formSearch)
    onFetchTypeAsset(token)
    onFetchCategory(token)
    onFetchEntity(token)
  },[token, onFetchTypeAsset, onFetchCategory,formSearch,onFetchAsset,changing,onFetchEntity])

  function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

  if (!isEmpty(typeAssets)) {
    optionType = (
      <Fragment>
        {typeAssets.map((type) => (        
          <option key={type.id_type} value={type.id_type}>{type.name}</option>
        ))}
      </Fragment>
    )
  }

  if (!isEmpty(categories)) {
    optionCategories = (
      <Fragment>
        {categories.map((category) => (        
          <option key={category.id_cat} value={category.id_cat}>{category.name}</option>
        ))}
      </Fragment>
    )
  }

  if (!isEmpty(entities)) {
    optionEntities = (
      <Fragment>
        {entities.map((entity) => (        
          <option key={entity.id_entity} value={entity.id_entity}>{entity.name}</option>
        ))}
      </Fragment>
    )
  }

  const handleSearch = event => {
    const target = event.target.name;
    event.persist()
    setFormSearch((formSearch) => ({
      ...formSearch,
      [target]: event.target.value
    }))
  }

  const handleChangePage = (event, newPage) => {
    onFetchAsset(newPage, token, formSearch)
  }

  if(!isEmpty(assets)){
    const page = assets.current_page - 1
    const rowsPerPage = assets.per_page
    const countRows = assets.total
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, countRows - page * rowsPerPage)

    tableBody = (
      <Fragment>
        <TableBody>
          {assets.assets.map((asset) => (
            <AsetWakaf
              asset={asset}
              key={asset.uuid}
              detailed={() => props.show(asset)}
              edited={() => props.edit(asset)}
              // deleted={() => {
              //   props.onCloseAlert()
              //   props.onDialogBox('Yakin ingin menghapus data Aset? ', asset, asset.uuid, actions.deleteAsetWakaf)
              // }}
            />
          ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[props.assets.per_page]}
              colSpan={8}
              count={countRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <div className={classes.row}>
        <Grid container spacing={2}>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <Typography variant="h6">Filter</Typography>
            <br />
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Paper component="form" className={classes.searchSelectRoot}>
                  <FormControl
                    // error={errors.id_type && true}
                    variant="outlined" className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="outlined-age-native-simple">Semua Jenis</InputLabel>
                    <Select
                      native
                      value={formSearch.id_type}
                      onChange={handleSearch}
                      label="Semua Jenis"
                      inputProps={{
                        name: 'id_type',
                        id: 'outlined-age-native-simple'
                      }}
                      name="id_type"
                    >
                      <option aria-label="None" value="" />
                      {optionType}
                    </Select>
                    {/* <FormHelperText>{errors.id_type && errors.id_type.message}</FormHelperText> */}
                  </FormControl>
                </Paper>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Paper component="form" className={classes.searchSelectRoot}>
                  <FormControl
                    // error={errors.id_cat && true}
                    variant="outlined" className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="outlined-age-native-simple">Semua Kategori</InputLabel>
                    <Select
                      native
                      value={formSearch.id_cat}
                      onChange={handleSearch}
                      label="Semua Kategori"
                      inputProps={{
                        name: 'id_cat',
                        id: 'outlined-age-native-simple'
                      }}
                      name="id_cat"
                    >
                      <option aria-label="None" value="" />
                      {optionCategories}
                    </Select>
                    {/* <FormHelperText>{errors.id_cat && errors.id_cat.message}</FormHelperText> */}
                  </FormControl>
                </Paper>
              </Grid>
              {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                <Paper component="form" className={classes.searchSelectRoot}>
                  <TextField
                    label="Tanggal Kadaluarsa"
                    variant="outlined"
                    type="date"
                    name="end_date"
                    fullWidth
                    onChange={handleSearch}
                    value={formSearch.end_date}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Paper>
              </Grid> */}
            </Grid>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Paper component="form" className={classes.searchSelectRoot}>
                  <FormControl
                    // error={errorStatus.status && true}
                    variant="outlined" className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="outlined-age-native-simple">Status</InputLabel>
                    <Select
                      native
                      defaultValue={formSearch.status}
                      onChange={handleSearch}
                      label="Status"
                      inputProps={{
                        name: 'status',
                        id: 'outlined-age-native-simple'
                      }}
                      name="status"
                      // inputRef={register}
                    >
                      <option aria-label="None" value="" />
                      <option value="0">Tidak Aktif</option>
                      <option value="1">Aktif</option>
                      <option value="2">Maintenance</option>
                      <option value="3">Rusak</option>
                      <option value="4">Dijual</option>
                      <option value="5">Hilang</option>
                    </Select>
                    {/* <FormHelperText>{errorStatus.status && errorStatus.status[0]}</FormHelperText> */}
                  </FormControl>
                </Paper>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Paper component="form" className={classes.searchSelectRoot}>
                  <FormControl
                    // error={errorStatus.status && true}
                    variant="outlined" className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="outlined-age-native-simple">Bagian</InputLabel>
                    <Select
                      native
                      defaultValue={formSearch.id_entity}
                      onChange={handleSearch}
                      label="Bagian"
                      inputProps={{
                        name: 'id_entity',
                        id: 'outlined-age-native-simple'
                      }}
                      name="id_entity"
                      // inputRef={register}
                    >
                      <option aria-label="None" value="" />
                      {optionEntities}
                    </Select>
                    {/* <FormHelperText>{errorStatus.status && errorStatus.status[0]}</FormHelperText> */}
                  </FormControl>
                </Paper>
              </Grid>
            </Grid>

          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}/>
        </Grid>
      </div>
      <hr />
      {/* search */}
      <div className={classes.row}>
        <Grid
          container
          spacing={2}
          justify='space-between'
        >
          <Grid item lg={8} md={8} sm={12} xs={12} />
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Paper className={classes.searchRoot}>
              <InputBase
                className={classes.input}
                name="name"
                placeholder="Cari Nama Aset"
                inputProps={{ 'aria-label': 'Cari Nama Aset' }}
                onChange={handleSearch}
                value={formSearch.name}
              />
              <Divider className={classes.divider} orientation="vertical" />
              <IconButton className={classes.iconButton} aria-label="search">
                <Search />
              </IconButton>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <br /> 

      <div className={classes.row}>
        <Grid container spacing={2}>
          <TableContainer component={Paper}>
            <div className={classes.topTable}>
              <Typography variant="h4">List Aset</Typography>
            </div>
            <hr />
            <Table className={classes.table} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {tableBody}
            </Table>
          </TableContainer>
        </Grid>
      </div>
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    assets: state.asset.assets,
    // page: state.asset.assets.current_page,
    typeAssets: state.typeAsset.typeAssets,
    entities: state.entity.entities,
    categories: state.category.categories,
    changing: state.asset.changing,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchAsset: (page, token, formSearch) => dispatch(actions.fetchAsetWakafs(page, token, formSearch)),
    onFetchTypeAsset: (token) => dispatch(actions.fetchTypeAssets(token)),
    onFetchCategory: (token) => dispatch(actions.fetchCategory(token)),
    onFetchEntity: (token) => dispatch(actions.fetchEntity(token))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListAsetWakaf);