import { Button, Card, CardActionArea, CardContent, CardMedia, Grid, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, 
  TableRow, Tooltip, Typography, CircularProgress as Loading } from '@material-ui/core';
import React, { Fragment, useEffect } from 'react';
import NumberFormat from 'react-number-format';
// import QRCode from 'qrcode.react';

import * as actions from '../../../../store/actions/index';
import { connect } from 'react-redux';

import Chart from './Chart/Chart';
import moment from 'moment';
import { Search as DetailIcon } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  imageList: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  detailAset: {
    color: '#263238',
    fontSize: '14px',
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.05px',
  }
}));

const DetailAset = props => {

  const { onFetchAssetDevauling, assetDevaluing, assetDetail, token } = props;
  const uuid = assetDetail.uuid;
  
  useEffect(() => {
    onFetchAssetDevauling(uuid, token)
  }, [onFetchAssetDevauling, uuid, token])

  const classes = useStyles();
  
  let detailAset = <Loading />;
  let gambarAset = <Loading />;
  let documentAset = <Loading />;
  let qr = <Loading />;

  function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

  let chart = <Loading />;
  if(!isEmpty(assetDevaluing)){
    chart = (
      <Chart assetDevaluing={assetDevaluing} />
    )
  }
  // let document = null;
  detailAset = (
    <Grid item lg={12} md={12} sm={12} xs={12} className={classes.detailAset}>
      <Grid container spacing={2}>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12} >
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={6} >
                  Jenis Asset
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6} >
                  : {assetDetail.type ? assetDetail.type.name : '-'}
                </Grid>  
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12} >
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={6} >
                  Kategori Aset
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6} >
                  : {assetDetail.category ? assetDetail.category.name : '-'}
                </Grid>  
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12} >
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={6} >
                  Tanggal Diterima
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6} >
                  : {moment(assetDetail.received_date).subtract(new Date().getTimezoneOffset(), 'minutes').format('DD/MM/YYYY HH:mm:ss')}
                </Grid>  
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12} >
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={6} >
                  Nilai Perolehan
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6} >
                  : <NumberFormat thousandSeparator={'.'} prefix={'Rp.'} decimalSeparator={','} displayType={'text'} value={assetDetail.value}/>
                </Grid>  
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12} >
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={6} >
                  Deskripsi
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6} >
                  : {assetDetail.description}
                </Grid>  
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3} />
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12} >
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={6} >
                  Nama Wakif
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6} >
                  : {assetDetail.wakif.length > 0 ? assetDetail.wakif[assetDetail.wakif.length - 1].name : '-'}
                </Grid>  
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12} >
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={6} >
                  Nomor Telepon
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6} >
                  : {assetDetail.wakif.length > 0 ? assetDetail.wakif[assetDetail.wakif.length - 1].phone : '-'}
                </Grid>  
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12} >
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={6} >
                  Alamat
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6} >
                  : {assetDetail.wakif.length > 0 ? assetDetail.wakif[assetDetail.wakif.length - 1].address : '-'}
                </Grid>  
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid> 
    </Grid>
  )

  if(assetDetail.image.length > 0){
    gambarAset = (
      <Fragment>
        <Grid
          container
          spacing={2}
        >
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Card className={classes.root}>
              <CardContent>
                <Grid
                  container
                  spacing={2}
                >
                {assetDetail.image.map(gambar => (
                  <Fragment key={gambar.uuid}>
                    <Grid item lg={3} md={3} sm={12} xs={12} >
                      <Card className={classes.imageList}>
                        <CardActionArea>
                          <CardMedia
                            className={classes.media}
                            image={gambar.url}
                            title="Contemplative Reptile"
                          />
                        </CardActionArea>
                        <CardContent>
                          <center>
                            <Typography variant="h5" style={{overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                              {gambar.caption}
                            </Typography>
                          </center>
                          
                        </CardContent>
                        
                      </Card>
                    </Grid>
                  </Fragment>
                ))}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Fragment>
    )
  }

  if(assetDetail.document.length > 0){
    documentAset = (
      <Fragment>
        <Grid
          container
          spacing={2}
        >
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Card className={classes.root}>
              <CardContent>
                <Grid
                  container
                  spacing={2}
                >
                  
                    <TableContainer component={Paper}>
                      <Table aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ width: '5%' }}>No</TableCell>
                            <TableCell style={{ width: '90%' }}>Nama Dokumen</TableCell>
                            <TableCell style={{ width: '5%' }}>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {assetDetail.document.map((document, index) => (
                            <TableRow key={index}>
                              <TableCell style={{ width: '5%' }}>{index+1}</TableCell>
                              <TableCell style={{ width: '90%' }}>{document.caption}</TableCell>
                              <TableCell style={{ width: '5%' }}>
                                <Tooltip title="Detail Document">
                                  <a href={document.url} target="blank">
                                    <IconButton aria-label="detail">
                                      <DetailIcon />
                                    </IconButton>
                                  </a>  
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Fragment>
    )
  }

  qr = (
    <Card className={classes.root}>
      <CardContent>
        <div>
          <center>
            {/* <QRCode value={assetDetail.uuid}/> */}
            <img src={assetDetail.qr_link} alt="qr_eoa_club" width="60%" height="auto"/>
          </center>
        </div>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <center>
            <Typography variant="h5">
              {assetDetail.name}
            </Typography>
          </center>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <a href={assetDetail.qr_link} target="blank">
            <Button type="button" variant="contained" style={{ backgroundColor: '#FF9300', color: '#FFFFFF' }} fullWidth disableElevation>
              CETAK QR CODE
            </Button>
          </a>
        </Grid>
      </CardContent>
    </Card>
  )

  

  return (
    <Fragment>

      <div className={classes.row}>
        <Grid
          container
          spacing={2}
          justify='space-between'
        >
          <Grid item lg={12} md={12} sm={12} xs={12} >
            <Card>
              <CardContent>
                {chart}

              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>

      <div className={classes.row}>
        <Grid
          container
          spacing={2}
          justify='space-between'
        >
          <Grid item lg={12} md={12} sm={12} xs={12} >
            <Card>
              <CardContent>
                {detailAset}

              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      <div className={classes.row}>
        <Grid
          container
          spacing={2}
          justify='space-between'
        >
          <Grid item lg={12} md={12} sm={12} xs={12} >
            <Grid
              container
              spacing={2}
            >
              <Grid item lg={6} md={6} sm={12} xs={12} >
                <Grid
                  container
                  spacing={2}
                >
                  <Grid item lg={12} md={12} sm={12} xs={12} >
                    {gambarAset}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} >
                    {documentAset}
                  </Grid>
                </Grid>
                
              </Grid>
              <Grid item lg={2} md={2} sm={2} xs={2} >

              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12} >
                {qr}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    assetDevaluing: state.asset.assetDevaluing,
    token: state.auth.token
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchAssetDevauling: (uuid, token) => dispatch(actions.fetchAssetDepreciation(uuid, token)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailAset);