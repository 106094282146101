import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';

import { Button, FormControl, Grid, InputLabel, makeStyles, Paper, Select, Typography, CircularProgress as Loading } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { DatePicker } from "@material-ui/pickers";
import moment from 'moment';

import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'

const schema = yup.object().shape({
})

const useStyles = makeStyles(theme => ({
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  contentSpace: {
    padding: '10px 32px'
    // backgroundColor: '#EEEEEE'
  },
}));

const QRAsetWakaf = props => {
  const classes = useStyles();
  const path = useHistory().location.pathname;

  const {onSetAuthRedirect, token, onFetchTypeAsset, onFetchCategory, categories, typeAssets, onDownloadQRAssets, loading} = props;

  useEffect(() => {
    onSetAuthRedirect(path)
    onFetchTypeAsset(token)
    onFetchCategory(token)
  }, [onSetAuthRedirect, path, token, onFetchTypeAsset, onFetchCategory]);

  let optionType = '';
  let optionCategories ='';
  let button = (
    <Button type="submit" variant="contained" style={{ backgroundColor: '#FF9300', color: '#FFFFFF' }} fullWidth disableElevation>
      Download
    </Button>
  );
  if(loading){
    button = <Loading />;
  }

  function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

  if (!isEmpty(typeAssets)) {
    optionType = (
      <Fragment>
        {typeAssets.map((type) => (        
          <option key={type.id_type} value={type.id_type}>{type.name}</option>
        ))}
      </Fragment>
    )
  }

  if (!isEmpty(categories)) {
    optionCategories = (
      <Fragment>
        {categories.map((category) => (        
          <option key={category.id_cat} value={category.id_cat}>{category.name}</option>
        ))}
      </Fragment>
    )
  }

  const [formState, setFormState] = useState({
    id_type: '',
    id_cat: '',
  })

  const [startDate, setStartDate] = useState(Date());
  const handleDateChange = event => {
    setStartDate(moment(event));
  }

  const [endDate, setEndDate] = useState(Date());
  const handleEndDateChange = event => {
    setEndDate(moment(event));
  }

  const handleChange = (event) => {
    const target = event.target.name
    event.persist()
    
    if(event.target.type === 'checkbox'){
      setFormState((formState) => ({
        ...formState,
        [target]: event.target.checked
      }))
    }else{
      setFormState((formState) => ({
        ...formState,
        [target]: event.target.value
      }))
    }
  }

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema)
  })

  const onSubmit = data => {
    if (startDate) {
      let date_from = moment(startDate).toDate();
      date_from.setDate(1)
      date_from.setHours(0);
      date_from.setMinutes(0);
      date_from.setSeconds(0);  
      data.date_from = moment.utc(date_from).format('YYYY-MM-DD HH:mm:ss');
    }
    
    if(endDate){
      let date_to = moment(endDate).toDate();
      let y = date_to.getFullYear(); 
      let m = date_to.getMonth();

      date_to.setDate(new Date(y, m + 1, 0).getDate())
      date_to.setHours(23);
      date_to.setMinutes(59);
      date_to.setSeconds(59); 
      
      data.date_to = moment.utc(date_to).format('YYYY-MM-DD HH:mm:ss');
    }
    onDownloadQRAssets(data, token);
  }

  return (
    <Fragment>
      <div className={classes.contentSpace}>
        {/* {loading} */}
        <div className={classes.row}>
          <Grid container >
            <Grid item lg={8} md={8} sm={12} xs={12} >
              <Typography variant="h4">Laporan QR Aset</Typography>
            </Grid>
          </Grid>
        </div>

        <div className={classes.row}>
          <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            spacing={2}
            justify='space-between'
          >
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="h6">Filter</Typography>
              <br />
              {/* <Grid container spacing={2}> */}
                {/* <Grid item lg={8} md={8} sm={8} xs={8}> */}
                
                  <Grid container spacing={2}>
                    <Grid item lg={4} md={4} sm={12} xs={12}>  
                      <Paper component="form" className={classes.searchSelectRoot}>
                        <FormControl
                          // error={errors.id_type && true}
                          variant="outlined" className={classes.formControl} fullWidth>
                          <InputLabel htmlFor="outlined-age-native-simple">Semua Jenis</InputLabel>
                          <Select
                            native
                            defaultValue={formState.id_type}
                            onChange={handleChange}
                            label="Semua Jenis"
                            inputProps={{
                              name: 'id_type',
                              id: 'outlined-age-native-simple'
                            }}
                            name="id_type"
                            inputRef={register}
                          >
                            <option aria-label="None" value="" />
                            {optionType}
                          </Select>
                          {/* <FormHelperText>{errors.id_type && errors.id_type.message}</FormHelperText> */}
                        </FormControl>
                      </Paper>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <Paper component="form" className={classes.searchSelectRoot}>
                        <FormControl
                          // error={errors.id_cat && true}
                          variant="outlined" className={classes.formControl} fullWidth>
                          <InputLabel htmlFor="outlined-age-native-simple">Semua Kategori</InputLabel>
                          <Select
                            native
                            defaultValue={formState.id_cat}
                            onChange={handleChange}
                            label="Semua Kategori"
                            inputProps={{
                              name: 'id_cat',
                              id: 'outlined-age-native-simple'
                            }}
                            name="id_cat"
                            inputRef={register}
                          >
                            <option aria-label="None" value="" />
                            {optionCategories}
                          </Select>
                          {/* <FormHelperText>{errors.id_cat && errors.id_cat.message}</FormHelperText> */}
                        </FormControl>
                      </Paper>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item lg={4} md={4} sm={12} xs={12}>  
                      <Paper component="form" className={classes.searchSelectRoot}>
                        {/* <KeyboardDatePicker
                          // autoOk
                          variant="dialog"
                          inputVariant="outlined"
                          openTo="year"
                          views={["year", "month"]}
                          label="Start Date"
                          helperText="Start from year selection"
                          value={startDate}
                          onChange={handleDateChange}
                          fullWidth
                          name="date_from"
                          inputRef={register}
                          InputAdornmentProps={{ position: "start" }}
                        /> */}
                        <DatePicker
                          variant="dialog"
                          openTo="year"
                          views={["year", "month"]}
                          label="Start Date"
                          helperText="Start from year selection"
                          disableFuture
                          value={startDate}
                          onChange={handleDateChange}
                          fullWidth
                          name="date_from"
                          inputRef={register}
                        />
                      </Paper>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <Paper component="form" className={classes.searchSelectRoot}>
                        {/* <KeyboardDatePicker
                          // autoOk
                          variant="dialog"
                          inputVariant="outlined"
                          openTo="year"
                          views={["year", "month"]}
                          label="End Date"
                          helperText="end from year selection"
                          value={endDate}
                          onChange={handleEndDateChange}
                          fullWidth
                          name="date_to"
                          inputRef={register}
                          InputAdornmentProps={{ position: "start" }}
                        /> */}
                        <DatePicker
                          variant="dialog"
                          openTo="year"
                          views={["year", "month"]}
                          label="End Date"
                          helperText="End from year selection"
                          disableFuture
                          value={endDate}
                          onChange={handleEndDateChange}
                          fullWidth
                          name="date_to"
                          inputRef={register}
                        />
                      </Paper>
                    </Grid>
                  {/* </Grid> */}
                {/* </Grid> */}
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12} >
                      {button}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          </form>
        </div>
        <hr />
      </div>

    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    typeAssets: state.typeAsset.typeAssets,
    categories: state.category.categories,
    loading: state.laporan.loading,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSetAuthRedirect: (path) => dispatch(actions.setAuthRedirectPath(path)),
    onFetchTypeAsset: (token) => dispatch(actions.fetchTypeAssets(token)),
    onFetchCategory: (token) => dispatch(actions.fetchCategory(token)),
    onDownloadQRAssets: (data, token) => dispatch(actions.downloadQRAssets(data, token)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QRAsetWakaf);