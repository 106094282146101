import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import thunk from 'redux-thunk'

import authReducer from './store/reducers/auth'
import dashboardReducer from './store/reducers/dashboard'
import alertReducer from './store/reducers/alert'
import assetReducer from './store/reducers/aset-wakaf'
import assetDetailReducer from './store/reducers/manajemenAset'
import typeAssetReducer from './store/reducers/typeAsset'
import categoryReducer from './store/reducers/category'
import notificationReducer from './store/reducers/notification'
import laporanReducer from './store/reducers/laporan'
import entitiesReducer from './store/reducers/entity'

import { ThemeProvider } from '@material-ui/styles'
import theme from './theme'

import * as serviceWorker from './serviceWorker'
// import {registerServiceWorker} from './register-sw'
// import { Router } from 'react-router-dom'
// import { createBrowserHistory } from 'history'

import Alert from './components/UI/Alert/SnackBar/SnackBar'
import DialogBox from './components/UI/Alert/Dialog/DialogBox'

// const history = createBrowserHistory();


// const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose
const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : (null || compose);

const rootReducer = combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
  alert: alertReducer,
  asset: assetReducer,
  assetDetail: assetDetailReducer,
  typeAsset: typeAssetReducer,
  category: categoryReducer,
  notification: notificationReducer,
  laporan: laporanReducer,
  entity: entitiesReducer,
})

const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk)
))
// const { isLatestVersion, emptyCacheStorage } = useClearCache({ duration: 5000 });

ReactDOM.render(
  
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Alert />
        <DialogBox />
        {/* <Router history={history}> */}
          <App />
        {/* </Router> */}
      </ThemeProvider>
    </Provider> 
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
// registerServiceWorker()
