import React from 'react';

import { Chip, IconButton, makeStyles, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { Delete, Edit as EditIcon } from '@material-ui/icons';
import palette from '../../../../../../../theme/palette';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  chipError: {
    color : palette.error.main,
    border: '1px solid'+palette.error.main,
    width: 150
  },
  chipSuccess: {
    color : palette.success.main,
    border: '1px solid'+palette.success.main,
    width: 150
  },
  chipWarning: {
    color : palette.warning.main,
    border: '1px solid'+palette.warning.main,
    width: 150
  },
  chipBlur: {
    color : '#707070',
    border: '1px solid #707070',
    width: 150
  },
  chipBlue: {
    color : '#0277BD',
    border: '1px solid #0277BD',
    width: 150
  },
  chipBlack: {
    color: '#231E2E',
    border: '1px solid #231E2E',
    width: 150
  }
}))

const TableBodyReminder = props => {
  const classes = useStyles();
  const { reminder } = props;

  let statusRepeat = '-';

  if(reminder.status_repeat === 0) {
    statusRepeat = <Chip variant="outlined" size="medium" className={classes.chipBlur} label="Jangan Pernah" />;
  }else if(reminder.status_repeat === 1){
    statusRepeat = <Chip variant="outlined" size="medium" className={classes.chipSuccess} label="Harian" />;
  }else if(reminder.status_repeat === 2){
    statusRepeat = <Chip variant="outlined" size="medium" className={classes.chipWarning} label="Mingguan" />;
  }else if(reminder.status_repeat === 3){
    statusRepeat = <Chip variant="outlined" size="medium"  className={classes.chipError} label="Bulanan" />;
  }else if(reminder.status_repeat === 4){
    statusRepeat = <Chip variant="outlined" size="medium" className={classes.chipBlue} label="Tahunan" />;
  }else{
    statusRepeat = '-';
  }

  let isUsingTime = '-';
  isUsingTime=(reminder.is_using_time === 1 ? <Chip variant="outlined" size="medium" className={classes.chipSuccess} label="Aktif" /> :
  <Chip variant="outlined" size="medium"  className={classes.chipError} label="Tidak Aktif" />);

  let reminder_date = moment(reminder.reminder_date).subtract(new Date().getTimezoneOffset(), 'minutes').format('DD/MM/YYYY HH:mm:ss');

  return (
    <TableRow key={reminder.id_reminder}>
      {/* <TableCell>
        {reminder.title}
      </TableCell> */}
      <TableCell>
        {reminder.body}
      </TableCell>
      <TableCell>
        {reminder_date}
      </TableCell>
      <TableCell>
        {isUsingTime}
      </TableCell>
      <TableCell>
        {statusRepeat}
      </TableCell>
      <TableCell style={{ width: 150 }} align="right">
        <Tooltip title="Edit Reminder Aset">
          <IconButton aria-label="edit" onClick={props.edited}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete Reminder Aset">
          <IconButton aria-label="delete" onClick={props.deleted}>
            <Delete style={{color: '#F83D61'}}/>
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

export default TableBodyReminder;