import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { Grid } from '@material-ui/core'
import { Bar } from 'react-chartjs-2';
import { options } from './Option'

const useStyles = makeStyles({
  content: {
    paddingTop: 20
  },
  root: {
    minWidth: 275,
    borderRadius: 8,
    color: '#231E2E',
    fontFamily: 'Nunito Sans, Roboto, sans-serif'
  },
  icon: {
    textAlign: 'right'
  },
  title: {
    fontSize: 18,
    paddingBottom: 15
  },
  value: {
    fontSize: 30,
    color: '#FF9300'
  }
})

const Chart = props => {
  const { chartCategory } = props;
  const classes = useStyles()

  let data = {};
  let kategori = [];
  let chartValue = [];

  console.log(chartCategory)

  if(chartCategory !== null && chartCategory.length > 0){
    // console.log(assetDevaluing.length);
    for (var i = 0; i < chartCategory.length; i++) {
      kategori.push(chartCategory[i].category_name);
      chartValue.push(chartCategory[i].count);
    }

    data = {
        labels: kategori,
        datasets: [
          {
            label : 'Grafik Kategori Aset',
            data: chartValue,
            backgroundColor: 'rgba(75,192,192,0.4)',
          }
        ]
    };
  }else{
    data = {
      labels: ["Belum tersedia"],
      datasets: [
        {
          label : 'Grafik Kategori Aset',
          data: ["Belum tersedia"],
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
        }
      ]
    };
  }

  return (
    <div className={classes.content}>
      <Card className={classes.root} variant="outlined">
        <CardContent>       
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography className={classes.title} gutterBottom> Grafik Kategori Aset </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Bar
                width={100}
                height={30}
                data={data}
                options={options}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}

export default Chart
