import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  dashboard: {},
  loading: false,
  error: {},
}

const dashboardStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const dashboardSuccess = (state, action) => {
  return updateObject(state, {
    dashboard: action.dashboard,
    loading: false
  })
}

const dashboardFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DASHBOARD_START: return dashboardStart(state, action)
    case actionTypes.DASHBOARD_SUCCESS: return dashboardSuccess(state, action)
    case actionTypes.DASHBOARD_FAIL: return dashboardFail(state, action)

    default: return state
  }
}

export default reducer