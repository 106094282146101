import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  token: null,
  user: {},
  userData: {},
  uploadUrl: null,
  changing: 0,
  error: {},
  loading: false,
  authRedirectPath: '/dashboard'
}

const authStart = (state, action) => {
  return updateObject(state, { error: null, loading: true })
}

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.tokenId,
    user: action.userId,
    userData: action.userData,
    error: null,
    loading: false
  })
}

const authFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false })
}

const authLogout = (state, action) => {
  return updateObject(state, { token: null, user: null })
}

const setAuthRedirectPath = (state, action) => {
  return updateObject(state, { authRedirectPath: action.path })
}

const authChangePasswordStart = (state, action) => {
  return updateObject(state, {loading: true})
}

const authChangePasswordSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    token: action.token
  })
}

const authChangePasswordFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  })
}

const authUpdateProfileStart = (state, action) => {
  return updateObject(state, {loading: true})
}

const authUpdateProfileSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    userData: action.userData,
  })
}

const authUpdateProfileFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  })
}

const authUploadImageStart = (state, action) => {
  return updateObject(state, {loading: true})
}

const authUploadImageSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    uploadUrl: action.uploadUrl,
  })
}

const authUploadImageFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  })
}

const authStoreTokenFirebaseStart = (state, action) => {
  return updateObject(state, {loading: true})
}

const authStoreTokenFirebaseSuccess = (state, action) => {
  return updateObject(state, {
    loading: false
  })
}

const authStoreTokenFirebaseFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  })
}

const authDeleteTokenFirebaseStart = (state, action) => {
  return updateObject(state, {loading: true})
}

const authDeleteTokenFirebaseSuccess = (state, action) => {
  return updateObject(state, {
    loading: false
  })
}

const authDeleteTokenFirebaseFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  })
}

const authUploadImageClear = (state, action) => {
  return updateObject(state, {
    uploadUrl: null
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START: return authStart(state, action)
    case actionTypes.AUTH_SUCCESS: return authSuccess(state, action)
    case actionTypes.AUTH_FAIL: return authFail(state, action)
    case actionTypes.AUTH_LOGOUT: return authLogout(state, action)

    case actionTypes.AUTH_CHANGE_PASSWORD_START: return authChangePasswordStart(state, action)
    case actionTypes.AUTH_CHANGE_PASSWORD_SUCCESS: return authChangePasswordSuccess(state, action)
    case actionTypes.AUTH_CHANGE_PASSWORD_FAIL: return authChangePasswordFail(state, action)

    case actionTypes.AUTH_UPDATE_PROFILE_START: return authUpdateProfileStart(state, action)
    case actionTypes.AUTH_UPDATE_PROFILE_SUCCESS: return authUpdateProfileSuccess(state, action)
    case actionTypes.AUTH_UPDATE_PROFILE_FAIL: return authUpdateProfileFail(state, action)
    case actionTypes.AUTH_UPLOAD_IMAGE_START: return authUploadImageStart(state, action)
    case actionTypes.AUTH_UPLOAD_IMAGE_SUCCESS: return authUploadImageSuccess(state, action)
    case actionTypes.AUTH_UPLOAD_IMAGE_FAIL: return authUploadImageFail(state, action)
    case actionTypes.AUTH_UPLOAD_IMAGE_CLEAR: return authUploadImageClear(state, action)

    case actionTypes.AUTH_STORE_TOKEN_FIREBASE_START: return authStoreTokenFirebaseStart(state, action)
    case actionTypes.AUTH_STORE_TOKEN_FIREBASE_SUCCESS: return authStoreTokenFirebaseSuccess(state, action)
    case actionTypes.AUTH_STORE_TOKEN_FIREBASE_FAIL: return authStoreTokenFirebaseFail(state, action)
    case actionTypes.AUTH_DELETE_TOKEN_FIREBASE_START: return authDeleteTokenFirebaseStart(state, action)
    case actionTypes.AUTH_DELETE_TOKEN_FIREBASE_SUCCESS: return authDeleteTokenFirebaseSuccess(state, action)
    case actionTypes.AUTH_DELETE_TOKEN_FIREBASE_FAIL: return authDeleteTokenFirebaseFail(state, action)

    case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state, action)
    default: return state
  }
}

export default reducer
