import { Avatar, Button, Card, CardContent, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

import Modal from '../../components/UI/Modal/ScrollDialog/ScrollDialog';
import Form from '../../components/View/Profile/Form/Form';
import UpdateProfile from '../../components/View/Profile/UpdateProfile/UpdateProfile';

import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  contentSpace: {
    padding: '10px 32px'
    // backgroundColor: '#EEEEEE'
  },
  media: {
    height: 300,
  },
  avatar: {
    width: '80%',
    height: 'auto'
  }
}));

const Profile = props => {
  const history = useHistory();
  const { userData, onSetAuthRedirect, token } = props;
  const path = history.location.pathname
  useEffect(() => {
    onSetAuthRedirect(path)
    closedModalDialog()
  }, [token, onSetAuthRedirect, path, userData])

  const classes = useStyles();

  const [form, setForm] = useState('');
  
  const changePassword = () => {
    setModalState({
      open: true,
      title: 'Ganti Password',
      maxWidth: 'sm'
    });
    setForm(<Form closedModalDialog={() => closedModalDialog()} />)
  }

  const changeProfile = () => {
    setModalState({
      open: true,
      title: 'Update Profile',
      maxWidth: 'sm'
    });
    setForm(<UpdateProfile userData={userData} closedModalDialog={() => closedModalDialog()} />)
  }

  const closedModalDialog = () => {
    setModalState({
      open: false,
      title: '',
      maxWidth: 'sm'
    });
    setForm('');
  }

  const [modalState, setModalState] = useState({
    open: false,
    title: '',
    maxWidth: 'sm',
  });

  return (
    <div className={classes.contentSpace}>

      <Modal
        maxWidth={modalState.maxWidth}
        open={modalState.open}
        title={modalState.title}
        onCloseModal={closedModalDialog}
        contentModal={form}
      />

      <div className={classes.row}>
        <Grid container spacing={2}>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <Typography variant="h4"> Profile </Typography>
          </Grid> 
        </Grid>
      </div>

      <div className={classes.row}>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12}  xs={12}>
            <Grid container spacing={2}>
              <Grid item lg={4} md={4} sm={4}  xs={12} />
              <Grid item lg={4} md={4} sm={4}  xs={12}>
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12}  xs={12} >
                    <Card>
                      <CardContent>
                        <Grid container>
                          <Grid item lg={12} md={12} sm={12}  xs={12} >
                            <center>
                            <Grid container>
                              <Grid item lg={12} md={12} sm={12}  xs={12} >
                                <Avatar 
                                  alt="profile" 
                                  src={userData.image} 
                                  className={classes.avatar}
                                />
                                {/* <img src={userData.image} alt="profile" width="100%" height="auto" /> */}
                              </Grid>
                            </Grid>
                            <hr />
                            <Grid container>
                              <Grid item lg={12} md={12}>
                                <Typography variant="h4" component="h2">
                                  {userData.name}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid item lg={12} md={12}>
                                <Typography variant="h5">
                                    {userData.role_name}
                                  </Typography>
                              </Grid>
                            </Grid>
                            </center>
                            
                          </Grid>
                        </Grid>
                      </CardContent>
                      {/* <CardActions> */}
                        <Grid container spacing={2}>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <div style={{padding: '0px 10px'}}>
                              <Button variant="contained" size="medium" fullWidth style={{ backgroundColor: '#011847', color: '#FFFFFF' }} onClick={() => changeProfile()}>
                                Ubah Profile
                              </Button>
                            </div>
                            
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                          <div style={{padding: '0px 10px 10px'}}>
                            <Button variant="contained" size="medium" fullWidth style={{ backgroundColor: '#FF9300', color: '#FFFFFF' }} onClick={() => changePassword()}>
                              Ubah Password
                            </Button>
                          </div>
                          </Grid>
                        </Grid>
                        
                        
                      {/* </CardActions> */}
                    </Card>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    userData: state.auth.userData,
    token: state.auth.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSetAuthRedirect: (path) => dispatch(actions.setAuthRedirectPath(path))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);