import * as actions from './actionTypes'
import axios from '../../axios-orders'
import { setAlert } from './alert'

export const authStart = () => {
  return {
    type: actions.AUTH_START
  }
}

export const authSuccess = (token, userId, userData) => {
  return {
    type: actions.AUTH_SUCCESS,
    tokenId: token,
    userId: userId,
    userData: userData
  }
}

export const authFail = (error) => {
  return {
    type: actions.AUTH_FAIL,
    error: error
  }
}

export const authLogout = () => {
  return {
    type: actions.AUTH_LOGOUT
  }
}

export const logout = () => {
  const firebaseToken = localStorage.getItem('firebaseToken');
  const jwtToken = localStorage.getItem('token');
  return dispatch => {
    if(firebaseToken && jwtToken){
      dispatch(deleteTokenFirebase(jwtToken, firebaseToken));
    }
    localStorage.removeItem('token');
    localStorage.removeItem('setAuthRedirectPath');
    localStorage.removeItem('user');
    localStorage.removeItem('userData');
    dispatch(authLogout());
  }
}

export const authDeletTokenFirebaseStart = () => {
  return {
    type: actions.AUTH_DELETE_TOKEN_FIREBASE_START
  }
}

export const authDeletTokenFirebaseSuccess = () => {
  return {
    type: actions.AUTH_DELETE_TOKEN_FIREBASE_SUCCESS
  }
}

export const authDeletTokenFirebaseFail = (error) => {
  return {
    type: actions.AUTH_DELETE_TOKEN_FIREBASE_FAIL,
    error: error
  }
}

export const deleteTokenFirebase = (jwtToken, firebaseToken) => {
  const storeData = { device_token: firebaseToken };
  return dispatch => {
    dispatch(authDeletTokenFirebaseStart())
    axios.post('users/token/destroy', storeData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${jwtToken}`
      }
    })
      .then(response => {
        localStorage.removeItem('firebaseToken')
        dispatch(authDeletTokenFirebaseSuccess())
      })
      .catch(err => {
        dispatch(authDeletTokenFirebaseFail(err.response.data.msg_str))
        // dispatch(setAlert(err.response.data.msg_str, 'error'))
      })
  }
}

export const checkAuthTimeout = (expirationTime) => {
  return dispatch => {
    setTimeout(() => {
      dispatch(logout())
    }, expirationTime * 1000)
  }
}

export const auth = (email, password, firebaseToken) => {
  return dispatch => {
    dispatch(authStart())
    const authData = {
      email: email,
      password: password
    }

    axios.post('auth/login', authData)
      .then(response => {
        localStorage.setItem('token', response.data.access_token)
        localStorage.setItem('user', response.data.user.id_user)
        localStorage.setItem('userData', JSON.stringify(response.data.user))
        dispatch(authSuccess(response.data.access_token, response.data.user.id_user, response.data.user))
        if(firebaseToken !== ''){
          dispatch(authStoreTokenFirebase(response.data.access_token, firebaseToken, 3))
        }
      })
      .catch(err => {
        dispatch(authFail(err.response.data.msg_str))
        dispatch(setAlert(err.response.data.msg_str, 'error'))
      })
  }
}

export const setAuthRedirectPath = (path) => {
  localStorage.setItem('setAuthRedirectPath', path)
  return {
    type: actions.SET_AUTH_REDIRECT_PATH,
    path: path
  }
}

export const authCheckState = () => {
  return dispatch => {
    const token = localStorage.getItem('token')
    if (!token) {
      dispatch(logout())
    } else {
      const user = localStorage.getItem('user')
      const redirectPath = localStorage.getItem('setAuthRedirectPath')
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (!(user && redirectPath && userData)) {
        dispatch(logout())
      } else {
        dispatch(authSuccess(token, user, userData))
        dispatch(setAuthRedirectPath(redirectPath))
      }
      // dispatch(checkAuthTimeout( (expirationDate.getTime() - new Date().getTime()) /1000 ));
    }
  }
}

export const authChangePasswordStart = () => {
  return {
    type: actions.AUTH_CHANGE_PASSWORD_START
  }
}

export const authChangePasswordSuccess = (token) => {
  return {
    type: actions.AUTH_CHANGE_PASSWORD_SUCCESS,
    token: token,
  }
}

export const authChangePasswordFail = (error) => {
  return {
    type: actions.AUTH_CHANGE_PASSWORD_FAIL,
    error: error
  }
}

export const authChangePassword = (storeData, token) => {
  console.log(storeData)
  return dispatch => {
    dispatch(authChangePasswordStart())
    axios.post('users/password', storeData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        localStorage.setItem('token', response.data.access_token)
        dispatch(authChangePasswordSuccess(response.data.access_token))
        dispatch(setAlert(response.data.msg_str, 'success'))
      })
      .catch(err => {
        console.log(err.response.data.msg_str)
        dispatch(authChangePasswordFail(err.response.data.msg_str))
        dispatch(setAlert(err.response.data.msg_str, 'error'))
      })
  }
}

export const authUpdateProfileStart = () => {
  return {
    type: actions.AUTH_UPDATE_PROFILE_START
  }
}

export const authUpdateProfileSuccess = (userData) => {
  return {
    type: actions.AUTH_UPDATE_PROFILE_SUCCESS,
    userData: userData,
  }
}

export const authUpdateProfileData = (token) => {
  return dispatch => {
    axios.get('users/profile', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        localStorage.removeItem('userData');
        localStorage.setItem('userData', JSON.stringify(response.data.user))
        dispatch(authUpdateProfileSuccess(response.data.user))
      })
      .catch(err => {

      })
  }
}

export const authUpdateProfileFail = (error) => {
  return {
    type: actions.AUTH_UPDATE_PROFILE_SUCCESS,
    error: error
  }
}

export const authUpdateProfile = (storeData, token) => {
  return dispatch => {
    dispatch(authUpdateProfileStart())
    axios.put('users/profile', storeData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        // dispatch(authUpdateProfileSuccess())
        dispatch(authUpdateProfileData(token))
        dispatch(setAlert(response.data.msg_str, 'success'))
      })
      .catch(err => {
        dispatch(authUpdateProfileFail(err.response.data.msg_str))
        dispatch(setAlert(err.response.data.msg_str, 'error'))
      })
  }
}

export const authUploadImageStart = () => {
  return {
    type: actions.AUTH_UPLOAD_IMAGE_START
  }
}

export const authUploadImageSuccess = (uploadUrl) => {
  return {
    type: actions.AUTH_UPLOAD_IMAGE_SUCCESS,
    uploadUrl: uploadUrl,
  }
}

export const authUploadImageFail = (error) => {
  return {
    type: actions.AUTH_UPLOAD_IMAGE_SUCCESS,
    error: error
  }
}

export const authUploadImage = (storeData, token) => {
  return dispatch => {
    dispatch(authUploadImageStart())
    const bodyFormData = new FormData()
    bodyFormData.set('image', storeData)

    axios({
      method: 'post',
      url: 'users/image/upload',
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        dispatch(authUploadImageSuccess(response.data.url))
        dispatch(setAlert(response.data.msg_str, 'success'))
      })
      .catch(err => {
        dispatch(authUploadImageFail(err.response.data.throwable))
        dispatch(setAlert(err.response.data.msg_str, 'error'))
      })
  }
}

export const authUploadImageClear = () => {
  return {
    type: actions.AUTH_UPLOAD_IMAGE_CLEAR
  }
}

export const authStoreTokenFirebaseStart = () => {
  return {
    type: actions.AUTH_STORE_TOKEN_FIREBASE_START
  }
}

export const authStoreTokenFirebaseSuccess = () => {
  return {
    type: actions.AUTH_STORE_TOKEN_FIREBASE_SUCCESS
  }
}

export const authStoreTokenFirebaseFail = (error) => {
  return {
    type: actions.AUTH_STORE_TOKEN_FIREBASE_FAIL
    
  }
}

export const authStoreTokenFirebase = (jwtToken, firebaseToken, type) => {
  const storeData = { device_token: firebaseToken, device_type: type }
  return dispatch => {
    dispatch(authStoreTokenFirebaseStart())
    axios.post('users/token', storeData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${jwtToken}`
      }
    })
      .then(response => {
        localStorage.setItem('firebaseToken', firebaseToken)
        dispatch(authStoreTokenFirebaseSuccess())
      })
      .catch(err => {
        dispatch(authStoreTokenFirebaseFail(err.response.data.msg_str))
        dispatch(setAlert(err.response.data.msg_str, 'error'))
      })
  }
}