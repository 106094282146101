import React from 'react';

import { IconButton, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
// import palette from '../../../../../../../theme/palette';

const CategoryComponent = props => {
  const { category } = props;

  const status = (category.is_active === 1 ? 'Aktif' : 'Tidak Aktif'); 

  return (
    <TableRow key={category.id_cat}>
      {/* <TableCell>
        {index+1}
      </TableCell> */}
      <TableCell>
        {category.name}
      </TableCell>
      <TableCell>
        {status}
      </TableCell>
      <TableCell style={{ width: 150 }} align="right">
        <Tooltip title="Edit Category">
          <IconButton aria-label="edit" onClick={props.edited}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

export default CategoryComponent;