import React, { Fragment, useEffect, useState } from 'react';
import { Grid, makeStyles, Typography, CircularProgress as Loading, Fab } from '@material-ui/core';

import CategoryList from '../../components/View/Master/Category/CategoryList/CategoryList';
import AddCategory from '../../components/View/Master/Category/AddCategory/AddCategory';
import AddTypeAsset from '../../components/View/Master/TypeAsset/AddTypeAsset/AddTypeAsset';
import Modal from '../../components/UI/Modal/ScrollDialog/ScrollDialog';

import { useHistory } from 'react-router-dom';
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import { AddCircle } from '@material-ui/icons';
import EditCategory from '../../components/View/Master/Category/EditCategory/EditCategory';
import TypeAssetList from '../../components/View/Master/TypeAsset/TypeAssetList/TypeAssetList';
import EditTypeAsset from '../../components/View/Master/TypeAsset/EditTypeAsset/EditTypeAsset';
import palette from '../../theme/palette'

const useStyles = makeStyles(theme => ({
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  contentSpace: {
    padding: '10px 32px'
    // backgroundColor: '#EEEEEE'
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(2)
  },
  sideRight: {
    textAlign: 'right'
  },
  btnAdd: {
    backgroundColor: '#FF9300',
    '&:hover': {
      backgroundColor: '#FF9300'
    },
    color: '#FFFFFF',
    width: '100%',
    justifyContent: 'left'
  }
  ,
  btnAddJenis: {
    backgroundColor: palette.primary.dark,
    '&:hover': {
      backgroundColor: palette.primary.main
    },
    color: '#FFFFFF',
    width: '100%',
    justifyContent: 'left'
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
    
  },
}))

const Master = props => {
  const { loadingState, onSetAuthRedirect, changing, authRedirectPath, changingTypeAsset } = props;
  const classes = useStyles();
  const path = useHistory().location.pathname;

  useEffect(() => {
    if(authRedirectPath !== path){
      onSetAuthRedirect(path)
    }
    closedModalDialog()
  }, [onSetAuthRedirect, authRedirectPath, path, changing, changingTypeAsset])

  let loading = '';
  if(loadingState){
    loading = <Loading />
  }

  const [modalState, setModalState] = useState({
    maxWidth: 'sm',
    title: '',
    open: false,
  })

  const [form, setForm] = useState('');

  const addKategori = () => {
    setModalState({
      maxWidth: 'xs',
      title: 'Tambah Kategori',
      open: true,
    });
    setForm(<AddCategory closedModalDialog={() => closedModalDialog()}/>);
  }

  const addJenis = () => {
    setModalState({
      maxWidth: 'xs',
      title: 'Tambah Jenis Aset',
      open: true,
    });
    setForm(<AddTypeAsset closedModalDialog={() => closedModalDialog()}/>);
  }

  const editCategory = (category) => {
    setModalState({
      maxWidth: 'xs',
      title: 'Edit Kategori',
      open: true,
    });
    setForm(<EditCategory categoryData={category} closedModalDialog={() => closedModalDialog()}/>);
  }

  const editTypeAsset = (typeAsset) => {
    setModalState({
      maxWidth: 'xs',
      title: 'Edit Jenis Aset',
      open: true,
    });
    setForm(<EditTypeAsset typeAssetData={typeAsset} closedModalDialog={() => closedModalDialog()}/>);
  }

  const closedModalDialog = () => {
    setModalState({
      maxWidth: 'sm',
      title: '',
      open: false,
    });
    setForm('');
  }

  // let categoryList = '';

  return (
    <Fragment>
      <div className={classes.contentSpace}>
        {loading}

        <Modal
          maxWidth={modalState.maxWidth}
          open={modalState.open}
          title={modalState.title}
          onCloseModal={closedModalDialog}
          contentModal={form}
        />
        
        <div className={classes.row}>
          <Grid container >
            <Grid item lg={4} md={4} sm={12} xs={12} >
              <Typography variant="h4">Master</Typography>
            </Grid>
            <Grid item lg={4} md={4} sm={false} xs={false} />
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12} >
                  <div className={classes.sideRight}>
                    <Grid container spacing={2}>
                      <Grid item lg={6} md={6} sm={12} xs={12} >
                        <Fab variant="extended" className={classes.btnAddJenis} onClick={() => addKategori()}>
                          <AddCircle className={classes.extendedIcon}/>
                          Kategori
                        </Fab>
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12} >
                        <Fab variant="extended" className={classes.btnAdd} onClick={() => addJenis()}>
                          <AddCircle className={classes.extendedIcon}/>
                          Jenis
                        </Fab>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <div className={classes.row}>
          <Grid container >
            <Grid item lg={12} md={12} sm={12} xs={12} >
              <CategoryList editCategory={(categoryData) => editCategory(categoryData)}/>
            </Grid>
          </Grid>
        </div>

        <div className={classes.row}>
          <Grid container >
            <Grid item lg={12} md={12} sm={12} xs={12} >
              <TypeAssetList editTypeAsset={(typeAssetData) => editTypeAsset(typeAssetData)}/>
            </Grid>
          </Grid>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    loadingState: state.category.loading,
    changing: state.category.changing,
    changingTypeAsset: state.typeAsset.changing,
    authRedirectPath: state.auth.authRedirectPath,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSetAuthRedirect: (path) => dispatch(actions.setAuthRedirectPath(path)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Master);