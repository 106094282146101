import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions/index';

import { Grid, Paper, Typography, makeStyles, TextField, Button, CircularProgress as Loading } from '@material-ui/core';

import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'

const useStyles = makeStyles(theme => ({
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  formRoot: {
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2, 4, 3)
  }
}));

const schema = yup.object().shape({
  // status: yup.string().required(),
  // description: yup.string().required()
});

const EditEntity = props => {
  const { onClearError, loading, error, token, entityData, onUpdateEntity } = props;

  useEffect(() => {
    onClearError()
  },[onClearError])

  const classes = useStyles();

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema)
  })
  
  const onSubmit = data => {
    onUpdateEntity(entityData.id_entity, data, token);
  }
  
  const handleChange = (event) => {
    const target = event.target.name
    event.persist()
    
    if(event.target.type === 'checkbox'){
      setFormState((formState) => ({
        ...formState,
        [target]: {
          value: event.target.checked
        }
      }))
    }else{
      setFormState((formState) => ({
        ...formState,
        [target]: {
          value: event.target.value
        }
      }))
    }
  }
  
  const [formState, setFormState] = useState({
    name: entityData.name,
    description: entityData.description
  });

  let button = (loading ? <Loading /> : (
    <Button type="submit" variant="contained" style={{ backgroundColor: '#FF9300', color: '#FFFFFF' }} fullWidth disableElevation>
      Simpan
    </Button>
  ))

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Paper className={classes.formRoot}>
            <Typography>Bagian</Typography>
            <div className={classes.row}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    label="Nama Bagian"
                    variant="outlined"
                    name="name"
                    fullWidth
                    inputRef={register}
                    error={error.name ? true : false}
                    helperText={error.name && error.name[0]}

                    defaultValue={formState.name}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={classes.row}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    label="Deskripsi"
                    variant="outlined"
                    name="description"
                    fullWidth
                    inputRef={register}
                    multiline
                    rows={4}
                    error={error.description ? true : false}
                    helperText={error.description && error.description[0]}

                    defaultValue={formState.description}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item lg={8} md={8} sm={8} xs={8} />
        <Grid item lg={4} md={4} sm={4} xs={4} >
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <Button variant="contained" fullWidth disableElevation onClick={props.closedModalDialog}>
                    Batal
                  </Button>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  {button}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        
      </Grid>
    </form>
  )
}

const mapStateToProps = state => {
  return {
    loading: state.entity.loading,
    error: state.entity.error,
    token: state.auth.token,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onClearError: () => dispatch(actions.clearErrorEntity()),
    onUpdateEntity: (id, storeData, token) => dispatch(actions.updateEntity(id, storeData, token))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditEntity);