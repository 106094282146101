import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  assets: {
    assets: [],
    current_page: 1,
    per_page: 0,
    total: 0
  },

  changing: 0,
  changingUpdate: 0,

  urlImage: [],
  urlDocument: [],

  images: [],
  documents: [],
  file: '',

  assetDevaluing: [],

  loading: false,
  loadingUploadImage: false,
  loadingUploadDocument: false,

  error: {},
  errorImage: null,
  errorDocument: null,
}

const fetchAsetWakafStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const fetchAsetWakafSuccess = (state, action) => {
  return updateObject(state, {
    assets: action.assets,
    loading: false
  })
}

const fetchAsetWakafFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const storeAsetWakafStart = (state, action) => {
  return updateObject(state, { 
    error: {},
    loading: true 
  })
}

const storeAsetWakafSuccess = (state, action) => {
  // const newOrder = updateObject(action.storeData, { id: action.storeDataId })
  return updateObject(state, {
    loading: false,
    changing: state.changing+1
  })
}

const storeAsetWakafFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  })
}

const updateAsetWakafStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const updateAsetWakafSuccess = (state, action) => {
  // const newOrder = updateObject(action.storeData, { id: action.storeDataId })
  return updateObject(state, {
    loading: false,
    changingUpdate: state.changingUpdate+1,
  })
}

const updateAsetWakafFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error
  })
}

const deleteAsetWakafStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const deleteAsetWakafFail = (state, action) => {
  return updateObject(state, { loading: false })
}

const deleteAsetWakafSuccess = (state, action) => {
  console.log('', state.assets)
  // console.log('',action.uuid)
  const updatedArray = state.assets.filter((a) => +a.uuid !== +action.id)
  // console.log(updatedArray);
  return updateObject(state, {
    loading: false,
    assets: updatedArray
  })
}

const searchAsetWakafStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const searchAsetWakafSuccess = (state, action) => {
  return updateObject(state, {
    assets: action.assets,
    loading: false
  })
}

const searchAsetWakafFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const uploadImagesAssetWakafStart = (state, action) => {
  return updateObject(state, { loadingUploadImage: true })
}

const uploadImagesAssetWakafSuccess = (state, action) => {
  const obj = [...state.urlImage, {url: action.url, caption: action.name}]
  return updateObject(state, {
    urlImage: obj,
    loadingUploadImage: false
  })
}

const uploadImagesAssetWakafFail = (state, action) => {
  return updateObject(state, {
    errorImage: action.error,
    loadingUploadImage: false
  })
}

const deleteImageAssetWakafRedux = (state, action) => {
  const updatedArray = state.urlImage.filter((a, index) => +index !== +action.index)
  return updateObject(state, {
    loading: false,
    urlImage: updatedArray
  })
}

const uploadDocumentsAssetWakafStart = (state, action) => {
  return updateObject(state, { loadingUploadDocument: true })
}

const uploadDocumentsAssetWakafSuccess = (state, action) => {
  const newArray = [...state.urlDocument, {url: action.url, caption: action.name}]
  return updateObject(state, {
    urlDocument: newArray,
    loadingUploadDocument: false
  })
}

const uploadDocumentsAssetWakafFail = (state, action) => {
  return updateObject(state, {
    errorDocument: action.error,
    loadingUploadDocument: false
  })
}

const deleteDocumentAssetWakafRedux = (state, action) => {
  const updatedArray = state.urlDocument.filter((a, index) => +index !== +action.index)
  return updateObject(state, {
    loading: false,
    urlDocument: updatedArray
  })
}

const clearImagesDocumentsAsetWakaf = (state, action) => {
  return updateObject(state, {
    loading: false,
    urlImage: [],
    urlDocument: []
  });
};

const clearErrorFormAsset = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: {}
  });
}

const deleteImageAssetWakafStart = (state, action) => {
  return updateObject(state, {
    loadingUploadImage: true
  });
}

const deleteImageAssetWakafSuccess = (state, action) => {
  const updatedArray = state.images.filter((a) => a.uuid !== action.uuid)
  return updateObject(state, {
    loadingUploadImage: false,
    images: updatedArray
  })
}

const deleteImageAssetWakafFail = (state, action) => {
  return updateObject(state, {
    loadingUploadImage: false,
    errorImage: action.error
  })
}

const deleteDocumentAssetWakafStart = (state, action) => {
  return updateObject(state, {
    loadingUploadDocument: true
  });
}

const deleteDocumentAssetWakafSuccess = (state, action) => {
  const updatedArray = state.documents.filter((a) => a.uuid !== action.uuid)
  return updateObject(state, {
    loadingUploadDocument: false,
    documents: updatedArray
  })
}

const deleteDocumentAssetWakafFail = (state, action) => {
  return updateObject(state, {
    loadingUploadDocument: false,
    errorDocument: action.error
  })
}

const fetchImageAssetWakafStart = (state, action) => {
  return updateObject(state, {
    loadingUploadImage: true
  })
}

const fetchImageAssetWakafSuccess = (state, action) => {
  return updateObject(state, {
    loadingUploadImage: false,
    images: action.images,
  })
}

const fetchImageAssetWakafFail = (state, action) => {
  return updateObject(state, {
    loadingUploadImage: false,
    errorImage: action.error 
  })
}

const fetchDocumentAssetWakafStart = (state, action) => {
  return updateObject(state, {
    loadingUploadDocument: true
  })
}

const fetchDocumentAssetWakafSuccess = (state, action) => {
  return updateObject(state, {
    loadingUploadDocument: false,
    documents: action.documents,
  })
}

const fetchDocumentAssetWakafFail = (state, action) => {
  return updateObject(state, {
    loadingUploadDocument: false,
    errorDocument: action.error 
  })
}

const clearImagesAssetWakaf = (state, action) => {
  return updateObject(state, {
    loadingUploadImage: false,
    images: [],
  })
}

const clearDocumentsAssetWakaf = (state, action) => {
  return updateObject(state, {
    loadingUploadDocument: false,
    documents: [],
  })
}

const addImageAssetWakafStart = (state, action) => {
  return updateObject(state, {
    loadingUploadImage: true,
  })
}

const addImageAssetWakafSuccess = (state, action) => {
  return updateObject(state, {
    loadingUploadImage: false,
  })
}

const addImageAssetWakafFail = (state, action) => {
  return updateObject(state, {
    loadingUploadImage: false,
    errorImage: action.error
  })
}

const addDocumentAssetWakafStart = (state, action) => {
  return updateObject(state, {
    loadingUploadDocument: true,
  })
}

const addDocumentAssetWakafSuccess = (state, action) => {
  return updateObject(state, {
    loadingUploadDocument: false,
  })
}

const addDocumentAssetWakafFail = (state, action) => {
  return updateObject(state, {
    loadingUploadDocument: false,
    errorDocument: action.error
  })
}

const fetchAssetDepreciationStart = (state, action) => {
  return updateObject(state, {
    assetDevaluing: [],
    loading: true
  })
}

const fetchAssetDepreciationSuccess = (state, action) => {
  // const newArray = [...assetDepreciation, action.dep]
  return updateObject(state, {
    loading: false,
    assetDevaluing: action.devaluing
  })
}

const fetchAssetDepreciationFail = (state, action) => {
  return updateObject(state, {
    error: action.error
  })
}

const downloadAssetStart = (state, action) => {
  return updateObject(state, {
    loading: true
  })
}

const downloadAssetSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    file: action.file
  })
}

const downloadAssetFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ASET_WAKAF_START: return fetchAsetWakafStart(state, action)
    case actionTypes.FETCH_ASET_WAKAF_SUCCESS: return fetchAsetWakafSuccess(state, action)
    case actionTypes.FETCH_ASET_WAKAF_FAIL: return fetchAsetWakafFail(state, action)
    case actionTypes.STORE_ASET_WAKAF_START: return storeAsetWakafStart(state, action)
    case actionTypes.STORE_ASET_WAKAF_SUCCESS: return storeAsetWakafSuccess(state, action)
    case actionTypes.STORE_ASET_WAKAF_FAIL: return storeAsetWakafFail(state, action)
    case actionTypes.UPDATE_ASET_WAKAF_START: return updateAsetWakafStart(state, action)
    case actionTypes.UPDATE_ASET_WAKAF_SUCCESS: return updateAsetWakafSuccess(state, action)
    case actionTypes.UPDATE_ASET_WAKAF_FAIL: return updateAsetWakafFail(state, action)
    case actionTypes.DELETE_ASET_WAKAF_START: return deleteAsetWakafStart(state, action)
    case actionTypes.DELETE_ASET_WAKAF_SUCCESS: return deleteAsetWakafSuccess(state, action)
    case actionTypes.SEARCH_ASET_WAKAF_START: return searchAsetWakafStart(state, action)
    case actionTypes.SEARCH_ASET_WAKAF_SUCCESS: return searchAsetWakafSuccess(state, action)
    case actionTypes.SEARCH_ASET_WAKAF_FAIL: return searchAsetWakafFail(state, action)
    case actionTypes.DELETE_ASET_WAKAF_FAIL: return deleteAsetWakafFail(state, action)
    case actionTypes.UPLOAD_IMAGES_ASSETS_WAKAF_START: return uploadImagesAssetWakafStart(state, action)
    case actionTypes.UPLOAD_IMAGES_ASSETS_WAKAF_SUCCESS: return uploadImagesAssetWakafSuccess(state, action)
    case actionTypes.UPLOAD_IMAGES_ASSETS_WAKAF_FAIL: return uploadImagesAssetWakafFail(state, action)
    case actionTypes.DELETE_IMAGES_ASSETS_WAKAF_REDUX: return deleteImageAssetWakafRedux(state, action)
    case actionTypes.UPLOAD_DOCUMENTS_ASSETS_WAKAF_START: return uploadDocumentsAssetWakafStart(state, action)
    case actionTypes.UPLOAD_DOCUMENTS_ASSETS_WAKAF_SUCCESS: return uploadDocumentsAssetWakafSuccess(state, action)
    case actionTypes.UPLOAD_DOCUMENTS_ASSETS_WAKAF_FAIL: return uploadDocumentsAssetWakafFail(state, action)
    case actionTypes.DELETE_DOCUMENTS_ASSETS_WAKAF_REDUX: return deleteDocumentAssetWakafRedux(state, action)
    case actionTypes.CLEAR_IMAGES_DOCUMENTS_ASET_WAKAF: return clearImagesDocumentsAsetWakaf(state, action)
    case actionTypes.CLEAR_ERROR_FORM_ASSET: return clearErrorFormAsset(state, action)
    case actionTypes.DELETE_IMAGE_ASSET_WAKAF_START: return deleteImageAssetWakafStart(state, action)
    case actionTypes.DELETE_IMAGE_ASSET_WAKAF_SUCCESS: return deleteImageAssetWakafSuccess(state, action)
    case actionTypes.DELETE_IMAGE_ASSET_WAKAF_FAIL: return deleteImageAssetWakafFail(state, action)
    case actionTypes.DELETE_DOCUMENT_ASSET_WAKAF_START: return deleteDocumentAssetWakafStart(state, action)
    case actionTypes.DELETE_DOCUMENT_ASSET_WAKAF_SUCCESS: return deleteDocumentAssetWakafSuccess(state, action)
    case actionTypes.DELETE_DOCUMENT_ASSET_WAKAF_FAIL: return deleteDocumentAssetWakafFail(state, action)
    case actionTypes.FETCH_IMAGE_ASSET_WAKAF_START: return fetchImageAssetWakafStart(state, action)
    case actionTypes.FETCH_IMAGE_ASSET_WAKAF_SUCCESS: return fetchImageAssetWakafSuccess(state, action)
    case actionTypes.FETCH_IMAGE_ASSET_WAKAF_FAIL: return fetchImageAssetWakafFail(state, action)
    case actionTypes.FETCH_DOCUMENT_ASSET_WAKAF_START: return fetchDocumentAssetWakafStart(state, action)
    case actionTypes.FETCH_DOCUMENT_ASSET_WAKAF_SUCCESS: return fetchDocumentAssetWakafSuccess(state, action)
    case actionTypes.FETCH_DOCUMENT_ASSET_WAKAF_FAIL: return fetchDocumentAssetWakafFail(state, action)
    case actionTypes.CLEAR_IMAGES_ASSET_WAKAF: return clearImagesAssetWakaf(state, action)
    case actionTypes.CLEAR_DOCUMENTS_ASSET_WAKAF: return clearDocumentsAssetWakaf(state, action)
    case actionTypes.ADD_IMAGE_ASSEET_WAKAF_START: return addImageAssetWakafStart(state, action)
    case actionTypes.ADD_IMAGE_ASSEET_WAKAF_SUCCESS: return addImageAssetWakafSuccess(state, action)
    case actionTypes.ADD_IMAGE_ASSEET_WAKAF_FAIL: return addImageAssetWakafFail(state, action)
    case actionTypes.ADD_DOCUMENT_ASSEET_WAKAF_START: return addDocumentAssetWakafStart(state, action)
    case actionTypes.ADD_DOCUMENT_ASSEET_WAKAF_SUCCESS: return addDocumentAssetWakafSuccess(state, action)
    case actionTypes.ADD_DOCUMENT_ASSEET_WAKAF_FAIL: return addDocumentAssetWakafFail(state, action)
    case actionTypes.FETCH_ASSET_DEPRECIATION_START: return fetchAssetDepreciationStart(state, action)
    case actionTypes.FETCH_ASSET_DEPRECIATION_SUCCESS: return fetchAssetDepreciationSuccess(state, action)
    case actionTypes.FETCH_ASSET_DEPRECIATION_FAIL: return fetchAssetDepreciationFail(state, action)

    case actionTypes.DOWNLOAD_ASSET_START: return downloadAssetStart(state, action)
    case actionTypes.DOWNLOAD_ASSET_SUCCESS: return downloadAssetSuccess(state, action)
    case actionTypes.DOWNLOAD_ASSET_FAIL: return downloadAssetFail(state, action)

    default: return state
  }
}

export default reducer
