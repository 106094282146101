import React, { useEffect } from 'react'
import * as actions from '../../../store/actions/index'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

const Logout = props => {
  const { onLogout } = props

  useEffect(() => {
    onLogout()
  }, [onLogout])

  props.onAlert('sukses logout', 'success')

  return <Redirect to="/login" />
}

const mapDispatchToProps = dispatch => {
  return {
    onLogout: () => dispatch(actions.logout()),
    onAlert: (msg, status) => dispatch(actions.setAlert(msg, status))
  }
}

export default connect(null, mapDispatchToProps)(Logout)
