import { Paper, Table, TableCell, TableContainer, TableHead, TableRow, Typography, makeStyles, TableBody, TableFooter, TablePagination, Grid, Fab } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import TablePaginationsArrayActions from '../../../../UI/Table/TablePaginationArrayActions/TablePaginationsArrayActions';
import TableBodyStatus from './ListStatus/TableBodyStatus/TableBodyStatus';

const useStyle = makeStyles(theme => ({
  table: {
    minWidth: 500
  },
  topTable: {
    padding: 16,
    backgroundColor: '#FAFAFA'
  },
  sideRight: {
    textAlign: 'right'
  },
  btnAdd: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
    color: '#FFFFFF',
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
}))

const columns = [
  { id: 'tanggal_input', label: 'Tanggal Input' },
  { id: 'status', label: 'Status' },
  { id: 'keterangan', label: 'Keterangan' },
  // { id: 'action', label: 'Action', align:"right"},
]

const ListStatus = props => {
  const { assetDetail, changingStatusAset } = props;

  useEffect(() => {
    backPageStart()
  },[changingStatusAset])

  const classes = useStyle();
  let tableBody = '';
  
  const backPageStart = () => {
    setPage(0)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(5)
  const countRows = assetDetail.status.length
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, countRows - page * rowsPerPage);

  if(assetDetail.status.length > 0){
    // let statusAsset = assetDetail.status.reverse();

    tableBody = (
      <Fragment>
        <TableBody>
          {
          // statusAsset.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((status) => (
            assetDetail.status.map((status) => (
            <TableBodyStatus
              status={status}
              key={status.id_status}
              edited={() => props.editStatusForm(status)}
            />
          )).reverse().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={4} />
            </TableRow>
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={5}
              colSpan={4}
              count={countRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              ActionsComponent={TablePaginationsArrayActions}
            />
          </TableRow>
        </TableFooter>
      </Fragment>
    )
  }

  return (
    <TableContainer component={Paper}>
      {/* <div className={classes.topTable}>
        <Typography variant="h4">History Status Asset</Typography>
      </div>
      <hr /> */}
      <div className={classes.topTable}>
        <Grid container spacing={2}>        
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Typography variant="h4">History Status Aset</Typography>
          </Grid>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <div className={classes.sideRight}>
              <Fab variant="extended" className={classes.btnAdd} onClick={() => props.addStatusForm()}>
                <AddCircle className={classes.extendedIcon} />
                Status
              </Fab>
            </div>
          </Grid>      
        </Grid>
      </div>
      <hr/>
      <Table className={classes.table} aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {tableBody}
      </Table>
    </TableContainer>
  )
}

const mapStateToProps = state => {
  return {
    changingStatusAset: state.assetDetail.changingStatusAset
  }
}

export default connect(mapStateToProps)(ListStatus);