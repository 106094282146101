import React from 'react'
import { makeStyles, CircularProgress } from '@material-ui/core'
import Aux from '../../../../hoc/Aux/Aux'
// import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  }
}))

const CircularLoading = props => {
  const classes = useStyles()

  return (
    <Aux className={classes.root}>
      
        <CircularProgress />
      
      {/* <CircularProgress color="secondary" /> */}
    </Aux>
  )
}

export default CircularLoading
