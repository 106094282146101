import React, { Fragment, useEffect } from 'react';
import { Paper, Table, TableCell, TableContainer, TableHead, TableRow, Typography, makeStyles, TableBody } from '@material-ui/core';

import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions/index';
import Category from './Category/Category';

const useStyle = makeStyles(theme => ({
  table: {
    minWidth: 500
  },
  topTable: {
    padding: 16,
    backgroundColor: '#FAFAFA'
  }
}))

const columns = [
  // { id: 'no', label: 'No' },
  { id: 'nama', label: 'Nama Kategori' },
  { id: 'status', label: 'Status' },
  { id: 'action', label: 'Action', align:"right"}
]

const CategoryList = props => {
  const { onFetchCategories, token, categories, changing } = props;
  useEffect(() => {
    onFetchCategories(token)
  },[onFetchCategories, token, changing])

  const classes = useStyle();

  function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

  let tableBody = ''
  if(!isEmpty(categories)){
    tableBody = (
      
        <TableBody>
          {categories.map((category, index) => (
            <Category
              category={category}
              key={category.id_cat}
              index={index}
              edited={() => props.editCategory(category)}
            />
          )).reverse()
          }
          {/* {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={8} />
            </TableRow>
          )} */}
        </TableBody>
      
    )
  }

  return (
    <Fragment>
      {/* {loading} */}
      <TableContainer component={Paper}>
        <div className={classes.topTable}>
          <Typography variant="h4">List Kategori</Typography>
        </div>
        <hr />
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {tableBody}
        </Table>
      </TableContainer>
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    loadingState: state.category.loading,
    changing: state.category.changing,
    categories: state.category.categories,
    token: state.auth.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchCategories: (token) => dispatch(actions.fetchCategory(token))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryList);