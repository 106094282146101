import React from 'react';
import { Chip, makeStyles, TableCell, TableRow } from '@material-ui/core'
import palette from '../../../../../../../theme/palette'
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  chipError: {
    color : palette.error.main,
    border: '1px solid'+palette.error.main,
    width: 150
  },
  chipSuccess: {
    color : palette.success.main,
    border: '1px solid'+palette.success.main,
    width: 150
  },
  chipWarning: {
    color : palette.warning.main,
    border: '1px solid'+palette.warning.main,
    width: 150
  },
  chipBlur: {
    color : '#707070',
    border: '1px solid #707070',
    width: 150
  },
  chipBlue: {
    color : '#0277BD',
    border: '1px solid #0277BD',
    width: 150
  },
  chipBlack: {
    color: '#231E2E',
    border: '1px solid #231E2E',
    width: 150
  }
}))

const TableBodyStatus = props => {
  const { status } = props;

  const classes = useStyles();
  let statusAset = '-';
  
  if(status.status === 0) {
    statusAset = <Chip variant="outlined" size="medium" className={classes.chipBlur} label="Tidak Aktif" />;
  }else if(status.status === 1){
    statusAset = <Chip variant="outlined" size="medium" className={classes.chipSuccess} label="Aktif" />;
  }else if(status.status === 2){
    statusAset = <Chip variant="outlined" size="medium" className={classes.chipWarning} label="Maintenance" />;
  }else if(status.status === 3){
    statusAset = <Chip variant="outlined" size="medium"  className={classes.chipError} label="Rusak" />;
  }else if(status.status === 4){
    statusAset = <Chip variant="outlined" size="medium" className={classes.chipBlue} label="Dijual" />;
  }else if(status.status === 5){
    statusAset = <Chip variant="outlined" size="medium" className={classes.chipBlack} label="Hilang" />;
  }else{
    statusAset = '-';
  }
  
  let input_date = moment(status.input_date).subtract(new Date().getTimezoneOffset(), 'minutes').format('DD/MM/YYYY HH:mm:ss');

  return (
    <TableRow key={status.id_status}>
      <TableCell>
        {input_date}
      </TableCell>
      <TableCell>
        {statusAset}
      </TableCell>
      <TableCell>
        {status.description}
      </TableCell>
      {/* <TableCell style={{ width: 150 }} align="right">
        <Tooltip title="Edit Status Aset">
          <IconButton aria-label="edit" onClick={props.edited}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      </TableCell> */}
    </TableRow>
  )
}

export default TableBodyStatus;