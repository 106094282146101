import React, { Fragment, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { CircularProgress as Loading, Grid, Typography } from '@material-ui/core'
import { connect } from 'react-redux'

import TotalAset from '../../components/View/Dashboard/TotalAset/TotalAset'
import TotalNilaiAset from '../../components/View/Dashboard/TotalNilaiAset/TotalNilaiAset'
import Chart from '../../components/View/Dashboard/ChartCategory/ChartCategory';
// import

import * as actions from '../../store/actions/index'
import StatusAset from '../../components/View/Dashboard/StatusAset/StatusAset'
import ChartEntity from '../../components/View/Dashboard/ChartEntity/ChartEntity'

const useStyles = makeStyles(theme => ({
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  contentSpace: {
    padding: '10px 32px',
    backgroundColor: '#EEEEEE'
  },
  bgColor: {
    backgroundColor: '#BCE0FD',
    height: '312px',
    position: 'absolute'
  }
}))

const Dashboard = props => {
  const { onSetAuthRedirect, token, dashboard, onFetchDashboard } = props

  const pathRedirect = window.location.pathname

  useEffect(() => {
    onSetAuthRedirect(pathRedirect)
    onFetchDashboard(token)
  }, [onSetAuthRedirect, pathRedirect, token, onFetchDashboard])

  function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

  let loading = (props.loading ? (<Loading />): null);
  let totalAset = '';
  let totalNilaiAset = '';
  let allAssetDepreciation = '';
  let totalDepresiasi = '';
  let statusAset = '';
  let chart = '';
  let chartEntitas = '';

  if(!isEmpty(dashboard)){
    totalAset = <TotalAset totalAset={dashboard.all_asset_count} />;
    totalNilaiAset = <TotalNilaiAset name='Total Nilai Aset' totalNilaiAset={dashboard.all_asset_sum} />;

    allAssetDepreciation = <TotalNilaiAset name='Total Nilai Aset Saat ini' totalNilaiAset={dashboard.all_asset_minus_depreciation} />;
    totalDepresiasi = <TotalNilaiAset name='Total Nilai Depresiasi' totalNilaiAset={dashboard.total_depreciation} />;

    statusAset = <StatusAset dashboardData={dashboard} />
    chart = <Chart chartCategory={dashboard.chart_category}/>
    chartEntitas = <ChartEntity chartEntity={dashboard.entity_asset_count}/>
  }

  const classes = useStyles()

  return (
    <Fragment>
      <div className={classes.contentSpace}>
        <div className={classes.bgColor}></div>
        <div className={classes.row}>
          <Grid container spacing={2} >
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <Typography variant="h4">Dashboard</Typography>
            </Grid>
          </Grid>
        </div>
        {loading}
        <div className={classes.row}>
          <Grid container spacing={2} 
            // justify='space-between'
          >
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                      {/* <Wakif /> */}
                      {totalAset}
                    </Grid>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                      {totalNilaiAset}
                      {/* <Wakif /> */}
                    </Grid>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                      {/* <Wakif /> */}
                      {totalDepresiasi}
                    </Grid>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                      {allAssetDepreciation}
                      {/* <Wakif /> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={8} ms={8} sm={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  {chart}
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  {chartEntitas}
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  {statusAset}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

      </div>
      {/* CONTENT */}
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    loading: state.dashboard.loading,
    dashboard: state.dashboard.dashboard
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSetAuthRedirect: (path) => dispatch(actions.setAuthRedirectPath(path)),
    onFetchDashboard: (token) => dispatch(actions.dashboard(token))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
