import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  typeAssets: {},
  loading: false,
  error: {},
  changing: 0,
}

const fetchTypeAssetStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const fetchTypeAssetSuccess = (state, action) => {
  return updateObject(state, {
    typeAssets: action.typeAssets,
    loading: false
  })
}

const fetchTypeAssetFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const storeTypeAssetStart = (state, action) => {
  return updateObject(state, {
    loading: true
  })
}

const storeTypeAssetSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    changing: state.changing+1
  })
}

const storeTypeAssetFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const clearErrorTypeAsset = (state, action) => {
  return updateObject(state, {
    error: {}
  })
}

const updateTypeAssetStart = ( state, action) => {
  return updateObject(state, {
    loading: true
  })
}

const updateTypeAssetSuccess = (state, action) => {
  return updateObject(state, {
    changing: state.changing+1,
    loading: false
  })
}

const updateTypeAssetFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TYPE_ASSET_START: return fetchTypeAssetStart(state, action)
    case actionTypes.FETCH_TYPE_ASSET_SUCCESS: return fetchTypeAssetSuccess(state, action)
    case actionTypes.FETCH_TYPE_ASSET_FAIL: return fetchTypeAssetFail(state, action)
    case actionTypes.STORE_TYPE_ASSET_START: return storeTypeAssetStart(state, action)
    case actionTypes.STORE_TYPE_ASSET_SUCCESS: return storeTypeAssetSuccess(state, action)
    case actionTypes.STORE_TYPE_ASSET_FAIL: return storeTypeAssetFail(state, action)
    case actionTypes.UPDATE_TYPE_ASSET_START: return updateTypeAssetStart(state, action)
    case actionTypes.UPDATE_TYPE_ASSET_SUCCESS: return updateTypeAssetSuccess(state, action)
    case actionTypes.UPDATE_TYPE_ASSET_FAIL: return updateTypeAssetFail(state, action)
    case actionTypes.CLEAR_ERROR_TYPE_ASSET: return clearErrorTypeAsset(state, action)
    default: return state
  }
}

export default reducer