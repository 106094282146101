import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';

import Dropzone from '../../../UI/Dropzone/Dropzone'

import { Grid, Paper, Typography, makeStyles, TextField, Button, Card, CardActionArea, CardMedia, CardContent } from '@material-ui/core';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import imageCompression from 'browser-image-compression';

const useStyles = makeStyles(theme => ({
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  formRoot: {
    // border: '1px groove #000',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2, 4, 3)
  },
  imageList: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  }
}));

const schema = yup.object().shape({
});

const UpdateProfile = props => {
  const classes = useStyles();
  const {token, onUpdateProfile, userData, uploadUrl, onUploadImage, onAlert, onClearImage} = props;

  useEffect(() => {
    onClearImage();
  }, [onClearImage])

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  })

  const onSubmit = data => {
    data.image = uploadUrl;
    onUpdateProfile(data, token)
  }

  const [formState, setFormState] = useState({
    name: userData.name,
    // description: entityData.description
  });

  function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

  const handleChange = (event) => {
    const target = event.target.name
    event.persist()
    
    if(event.target.type === 'checkbox'){
      setFormState((formState) => ({
        ...formState,
        [target]: {
          value: event.target.checked
        }
      }))
    }else{
      setFormState((formState) => ({
        ...formState,
        [target]: {
          value: event.target.value
        }
      }))
    }
  }

  const [image, setImage] = useState('');

  const handleChangeBanner = event => {
    setImage(event)
    const imageFile = event[0];
    const options = {
      maxSizeMB: 4,
      maxWidthOrHeight: 512,
      useWebWorker: true
    }
    imageCompression(imageFile, options)
      .then(function (compressedFile) {
        let fileUpload = new File(
          [compressedFile],
          compressedFile.name,
          {
            type: compressedFile.type,
            path: compressedFile.name
          })
        // console.log(fileUpload);
        return onUploadImage(fileUpload, token); // write your own logic
      })
      .catch(function (error) {
        onAlert(error.message, 'error')
      });
  }

  let imageUploadSukes = (
    <Grid item lg={3} md={3} sm={12} xs={12}>
      <Card className={classes.imageList}>
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={userData.image}
            title="Contemplative Reptile"
          />
        </CardActionArea>
        <CardContent>
          <Typography variant="h5">
            image upload
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );

  if (!isEmpty(uploadUrl)) {
    // imageUploadSukes = <img src={uploadUrl} alt="profile" width="50%" height="auto" />;
    // console.log(assetImageUrl);
    imageUploadSukes = (
      <Fragment>          
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Card className={classes.imageList}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={uploadUrl}
                title="Contemplative Reptile"
              />
            </CardActionArea>
            <CardContent>
              <Typography variant="h5">
                image upload
              </Typography>
            </CardContent>
          </Card>
        </Grid>          
      </Fragment>
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Paper className={classes.formRoot}>
            <Typography>Update Profile</Typography>
            <div className={classes.row}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="name"
                    label="Name"
                    inputRef={register}
                    error={!!errors.name}
                    helperText={errors.name && errors.name.message}
                    className={classes.textField}
                    onChange={handleChange}
                    defaultValue={formState.name}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={classes.row}>

              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Dropzone multiple={false} fileType={'image/*'} value={image} handleChangeBanner={handleChangeBanner} />
                </Grid>
                {/* {console.log(loadingUploadImage)} */}
                {/* {loadingUploadImages} */}
              </Grid>

              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                <Fragment>
                  <Grid container spacing={2}>
                    {/* {imageAsetApi} */}
                    {imageUploadSukes}
                  </Grid>
                </Fragment>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item lg={8} md={8} sm={12} xs={12}>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Button type="submit" variant="contained" fullWidth onClick={props.closedModalDialog} disableElevation>
                Batal
              </Button>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Button type="submit" variant="contained" style={{ backgroundColor: '#FF9300', color: '#FFFFFF' }} fullWidth disableElevation>
                Simpan
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    uploadUrl: state.auth.uploadUrl,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onUpdateProfile:(data, token) => dispatch(actions.authUpdateProfile(data, token)),
    onUploadImage:(data, token) => dispatch(actions.authUploadImage(data, token)),
    onClearImage:() => dispatch(actions.authUploadImageClear()),
    onAlert: (msg, status) => dispatch(actions.setAlert(msg, status)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProfile);