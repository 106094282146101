import * as actions from './actionTypes'
import axios from '../../axios-orders'
import fileDownload from 'js-file-download'
import { setAlert } from './alert'

export const downloadAssetsStart = () => {
  return {
    type: actions.DOWNLOAD_LAPORAN_ASSETS_START
  }
}

export const downloadAssetsSuccess = (file) => {
  return {
    type: actions.DOWNLOAD_LAPORAN_ASSETS_SUCCESS,
    file: file
  }
}

export const downloadAssetsFail = (error) => {
  return {
    type: actions.DOWNLOAD_LAPORAN_ASSETS_FAIL,
    error: error
  }
}

export const downloadAssets = (storeData, token) => {
  let param = 'export_mode='+storeData.export_mode+'&date_from='+storeData.date_from+'&date_to='+storeData.date_to+'&id_cat='+storeData.id_cat+'&id_type='+storeData.id_type;
  return dispatch => {
    dispatch(downloadAssetsStart())
    axios.get('assets/report/all?' + param, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      },
      responseType: 'blob'
    })
      .then(res => {
        fileDownload(res.data, 'download-asset.xlsx')
        dispatch(downloadAssetsSuccess(res.data))
        dispatch(setAlert('download success', 'success'))
      })
      .catch(err => {
        dispatch(downloadAssetsFail(err.response.data.msg_str))
        dispatch(setAlert('download failed', 'error'))
      })
  }
}

export const downloadQRAssetsStart = () => {
  return {
    type: actions.DOWNLOAD_LAPORAN_QR_ASSETS_START
  }
}

export const downloadQRAssetsSuccess = (file) => {
  return {
    type: actions.DOWNLOAD_LAPORAN_QR_ASSETS_SUCCESS,
    file: file
  }
}

export const downloadQRAssetsFail = (error) => {
  return {
    type: actions.DOWNLOAD_LAPORAN_QR_ASSETS_FAIL,
    error: error
  }
}

export const downloadQRAssets = (storeData, token) => {
  let param = 'date_from='+storeData.date_from+'&date_to='+storeData.date_to+'&id_cat='+storeData.id_cat+'&id_type='+storeData.id_type;
  return dispatch => {
    dispatch(downloadQRAssetsStart())
    axios.get('assets/report/bulkqr?' + param, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      },
      responseType: 'blob'
    })
      .then(res => {
        fileDownload(res.data, 'download-qr-asset.pdf')
        dispatch(downloadQRAssetsSuccess(res.data))
        dispatch(setAlert('download success', 'success'))
      })
      .catch(err => {
        dispatch(downloadQRAssetsFail(err.response.data.msg_str))
        dispatch(setAlert('download failed', 'error'))
      })
  }
}