import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import { connect } from 'react-redux'
import * as actions from '../../../../store/actions/index'
// import Supplier from '../../../View/Supplier/Supplier';

const DialogBox = props => {
  const { openDialogBox, payload } = props.alerts

  const { token } = props

  let dialogBox = null
  if (openDialogBox) {
    dialogBox = (
      <Dialog
        open={openDialogBox}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={props.onClosedDialog}
      >
        <DialogTitle id="alert-dialog-title">{payload.header}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {/* <Typography variant="h4" color="inherit"> */}
            {payload.message}
          {/* </Typography> */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClosedDialog} color="primary">
            cancel
          </Button>
          <Button onClick={() => {
            props.onDeleted(payload.uniqCode, payload.action, token)
            props.onClosedDialog()
            // props.onAlert("Sukses Hapus data", "success");
          }} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <div>
      {dialogBox}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    alerts: state.alert,
    token: state.auth.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onClosedDialog: () => dispatch(actions.removeDialogBox()),
    onDeleted: (id, test, token) => dispatch(test(id, token)),
    onAlert: (message, alertType) => dispatch(actions.setAlert(message, alertType))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogBox)
