import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  assets: {},
  loading: false,
  error: {},
  errorReminder: {},
  // errorStatus: 
  changing: 0,
  changingStatusAset: 0,
  changingReminderAset: 0,
}

const fetchAssetDetailStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const fetchAssetDetailSuccess = (state, action) => {
  return updateObject(state, {
    assets: action.assets,
    loading: false
  })
}

const fetchAssetDetailFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const storeGiveAssetStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const storeGiveAssetSuccess = (state, action) => {
  return updateObject(state, {
    changing: state.changing+1,
    loading: false
  })
}

const storeGiveAssetFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const updateGiveAssetStart = (state, action) => {
  return updateObject(state, {
    loading: true
  })
}

const updateGiveAssetSuccess = (state, action) => {
  return updateObject(state, {
    changing: state.changing+1,
    loading: false
  })
}

const updateGiveAssetFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error
  })
}

const storeStatusAssetStart = (state, action) => {
  return updateObject(state, {
    loading: true
  })
}

const storeStatusAssetSuccess = (state, action) => {
  return updateObject(state, {
    changing: state.changing+1,
    changingStatusAset: state.changingStatusAset+1,
    loading: false
  })
}

const storeStatusAssetFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error
  })
}

const updateStatusAssetStart = (state, action) => {
  return updateObject(state, {
    loading: true
  })
}

const updateStatusAssetSuccess = (state, action) => {
  return updateObject(state, {
    changing: state.changing+1,
    loading: false
  })
}

const updateStatusAssetFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error
  })
}

const storeReminderAssetStart = (state, action) => {
  return updateObject(state, {
    loading: true
  })
}

const storeReminderAssetSuccess = (state, action) => {
  return updateObject(state, {
    changing: state.changing+1,
    changingReminderAset: state.changingReminderAset+1,
    loading: false
  })
}

const storeReminderAssetFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    errorReminder: action.error
  })
}

const updateReminderAssetStart = (state, action) => {
  return updateObject(state, {
    loading: true
  })
}

const updateReminderAssetSuccess = (state, action) => {
  return updateObject(state, {
    changing: state.changing+1,
    loading: false
  })
}

const updateReminderAssetFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    errorReminder: action.error
  })
}

const deleteReminderAssetStart = (state, action) => {
  return updateObject(state, {
    loading: true
  })
}

const deleteReminderAssetSuccess = (state, action) => {
  // const updatedArray = state.assets.reminder.filter((a) => +a.id_reminder !== +action.id)
  // console.log(updatedArray);
  return updateObject(state, {
    loading: false,
    changing: state.changing+1
  })
}

const deleteReminderAssetFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    errorReminder: action.error
  })
}

const clearErrorReminder = (state, action) => {
  return updateObject(state, {
    errorReminder: {}
  })
}

const clearError = (state, action) => {
  return updateObject(state, {
    error: {}
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ASET_DETAIL_START: return fetchAssetDetailStart(state, action)
    case actionTypes.FETCH_ASET_DETAIL_SUCCESS: return fetchAssetDetailSuccess(state, action)
    case actionTypes.FETCH_ASET_DETAIL_FAIL: return fetchAssetDetailFail(state, action)
    case actionTypes.STORE_GIVE_ASSET_START: return storeGiveAssetStart(state, action)
    case actionTypes.STORE_GIVE_ASSET_SUCCESS: return storeGiveAssetSuccess(state, action)
    case actionTypes.STORE_GIVE_ASSET_FAIL: return storeGiveAssetFail(state, action)
    case actionTypes.UPDATE_GIVE_ASSET_START: return updateGiveAssetStart(state, action)
    case actionTypes.UPDATE_GIVE_ASSET_SUCCESS: return updateGiveAssetSuccess(state, action)
    case actionTypes.UPDATE_GIVE_ASSET_FAIL: return updateGiveAssetFail(state, action)
    case actionTypes.STORE_STATUS_ASSET_START: return storeStatusAssetStart(state, action)
    case actionTypes.STORE_STATUS_ASSET_SUCCESS: return storeStatusAssetSuccess(state, action)
    case actionTypes.STORE_STATUS_ASSET_FAIL: return storeStatusAssetFail(state, action)
    case actionTypes.UPDATE_STATUS_ASSET_START: return updateStatusAssetStart(state, action)
    case actionTypes.UPDATE_STATUS_ASSET_SUCCESS: return updateStatusAssetSuccess(state, action)
    case actionTypes.UPDATE_STATUS_ASSET_FAIL: return updateStatusAssetFail(state, action)
    case actionTypes.STORE_REMINDER_ASSET_START: return storeReminderAssetStart(state, action)
    case actionTypes.STORE_REMINDER_ASSET_SUCCESS: return storeReminderAssetSuccess(state, action)
    case actionTypes.STORE_REMINDER_ASSET_FAIL: return storeReminderAssetFail(state, action)
    case actionTypes.UPDATE_REMINDER_ASSET_START: return updateReminderAssetStart(state, action)
    case actionTypes.UPDATE_REMINDER_ASSET_SUCCESS: return updateReminderAssetSuccess(state, action)
    case actionTypes.UPDATE_REMINDER_ASSET_FAIL: return updateReminderAssetFail(state, action)
    case actionTypes.DELETE_REMINDER_ASSET_START: return deleteReminderAssetStart(state, action)
    case actionTypes.DELETE_REMINDER_ASSET_SUCCESS: return deleteReminderAssetSuccess(state, action)
    case actionTypes.DELETE_REMINDER_ASSET_FAIL: return deleteReminderAssetFail(state, action)
    case actionTypes.CLEAR_ERROR_REMINDER: return clearErrorReminder(state, action)
    case actionTypes.CLEAR_ERROR: return clearError(state, action)
    default: return state
  }
}

export default reducer