import { Grid, Paper, Typography, makeStyles, FormControl, InputLabel, Select, FormHelperText, TextField, Button } from '@material-ui/core';
import React, { Fragment, useState, useEffect } from 'react';
import moment from 'moment';

import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'

import * as actions from '../../../../../../../store/actions/index';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  formRoot: {
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2, 4, 3)
  }
}));

const schema = yup.object().shape({
  // status: yup.string().required(),
  // description: yup.string().required()
})

const AddStatusForm = props => {
  const history = useHistory();
  const classes = useStyles();
  const { onUpdateStatus, token, statusAset, onClearError } = props;
  useEffect(() => {
    onClearError()
  },[onClearError])

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  })

  const onSubmit = data => {
    // data.uuid = assetDetail.uuid;
    data.input_date = moment.utc().format('YYYY-MM-DD HH:mm:ss');
    onUpdateStatus(statusAset.id_status, data, token, history)
  }

  const handleChange = (event) => {
    const target = event.target.name
    event.persist()

    setFormState((formState) => ({
      ...formState,
      [target]: {
        ...formState.jk,
        value: event.target.value
      }
    }))
  }

  const [formState, setFormState] = useState({
    status: {
      value: statusAset.status
    },
    description: {
      value: statusAset.description
    }
  })
  
  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            
              <Paper className={classes.formRoot}>
                <Typography>Status</Typography>
                <div className={classes.row}>
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <FormControl
                        error={errors.status && true}
                        variant="outlined" className={classes.formControl} fullWidth>
                        <InputLabel htmlFor="outlined-age-native-simple">Status</InputLabel>
                        <Select
                          native
                          defaultValue={formState.status.value}
                          onChange={handleChange}
                          label="Status"
                          inputProps={{
                            name: 'status',
                            id: 'outlined-age-native-simple'
                          }}
                          name="status"
                          inputRef={register}
                        >
                          <option aria-label="None" value="" />
                          <option value="0">Tidak Aktif</option>
                          <option value="1">Aktif</option>
                          <option value="2">Maintenance</option>
                          <option value="3">Rusak</option>
                          <option value="4">Dijual</option>
                          <option value="5">Hilang</option>
                        </Select>
                        <FormHelperText>{errors.status && errors.status.message}</FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.row}>
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <TextField
                        label="Keterangan"
                        variant="outlined"
                        name="description"
                        fullWidth
                        multiline
                        rows={9}
                        inputRef={register}
                        error={!!errors.description}
                        helperText={errors.description && errors.description.message}

                        defaultValue={formState.description.value}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
        >
          <Grid item lg={8} md={8} sm={8} xs={8}></Grid>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Button variant="contained" fullWidth disableElevation onClick={props.closedModalDialog}>
                  Batal
                </Button>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Button type="submit" variant="contained" style={{ backgroundColor: '#FF9300', color: '#FFFFFF' }} fullWidth disableElevation>
                  Simpan
                </Button>
              </Grid>
            </Grid>
          </Grid>
          
        </Grid>
      </form>
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    errorStatus: state.assetDetail.error
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onUpdateStatus: (id, storeData, token, history) => dispatch(actions.updateStatusAsset(id, storeData, token, history)),
    onClearError: () => dispatch(actions.clearError())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddStatusForm);