import React, { Fragment } from 'react'
import Login from '../../../containers/Auth/Login/Login'

const Minimal = props => {
  return (
    <Fragment>
      <Login />
    </Fragment>
  )
}

export default Minimal
