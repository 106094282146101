import { Grid, CircularProgress } from '@material-ui/core';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../../store/actions/index';
import moment from  'moment';

const NotificationShow = props => {
  const {notif, assetDetail, token, onFetchAsset} = props;

  useEffect(() => {
    onFetchAsset(notif.asset.uuid, token)
  }, [onFetchAsset, notif, token]);

  function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

  let entity = <CircularProgress />;
  const tgl = moment(notif.created_at).format('DD/MM/YYYY HH:mm:ss');

  if(!isEmpty(assetDetail)){
    entity = (assetDetail.history.length !== 0) ? assetDetail.history[assetDetail.history.length - 1].entity.name : '-';
  }
  
  return (
    <Fragment>
      <Grid container >
        <Grid item lg={12} sm={12} md={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={4} sm={4} md={12} xs={12}>
              <img src={notif.asset_image[notif.asset_image.length-1].url} alt="aset" width="100%" height="auto" />
            </Grid>
            <Grid item lg={8} sm={8} md={12} xs={12}>
              {notif.asset.name}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={4} sm={4} md={12} xs={12}>
              PIC
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={4} sm={4} md={12} xs={12}>
              Pengguna
            </Grid>
            <Grid item lg={8} sm={8} md={12} xs={12}>
              {notif.asset_history.length > 0 ? notif.asset_history[notif.asset_history.length-1].name : '-'}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={4} sm={4} md={12} xs={12}>
              Entitas
            </Grid>
            <Grid item lg={8} sm={8} md={12} xs={12}>
              {entity}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={4} sm={4} md={12} xs={12}>
              Lokasi
            </Grid>
            <Grid item lg={8} sm={8} md={12} xs={12}>
              {notif.asset_history.length > 0 ? notif.asset_history[notif.asset_history.length-1].location : '-'}
            </Grid>
          </Grid>
          <hr />
          <Grid container spacing={2}>
            <Grid item lg={4} sm={4} md={12} xs={12}>
              Pengigat
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={4} sm={4} md={12} xs={12}>
              Tanggal
            </Grid>
            <Grid item lg={8} sm={8} md={12} xs={12}>
              {tgl}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={4} sm={4} md={12} xs={12}>
              Deskripsi
            </Grid>
            <Grid item lg={8} sm={8} md={12} xs={12}>
              {notif.body}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    assetDetail: state.assetDetail.assets,
    loading: state.assetDetail.loading,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchAsset: (uuid, token) => dispatch(actions.fetchAssetDetails(uuid, token)),
    // onFetchAssetDevauling: (uuid, token) => dispatch(actions.fetchAssetDepreciation(uuid, token)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationShow);