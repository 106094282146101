import React, { Fragment, useEffect, useState } from 'react';
import { Grid, makeStyles, Typography, CircularProgress as Loading } from '@material-ui/core';

import Modal from '../../components/UI/Modal/ScrollDialog/ScrollDialog';

import { useHistory } from 'react-router-dom';
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import NotificationList from '../../components/View/Notification/NotificationList/NotificationList';
import NotificationShow from '../../components/View/Notification/NotificationShow/NotificationShow';

const useStyles = makeStyles(theme => ({
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  contentSpace: {
    padding: '10px 32px'
    // backgroundColor: '#EEEEEE'
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(2)
  },
  sideRight: {
    textAlign: 'right'
  },
  btnAdd: {
    backgroundColor: '#FF9300',
    '&:hover': {
      backgroundColor: '#FF9300'
    },
    color: '#FFFFFF',
    width: '100%',
    justifyContent: 'left'
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
    
  },
}))

const Notifications = props => {
  const { loadingNotif, onSetAuthRedirect, authRedirectPath } = props;
  const classes = useStyles();
  const path = useHistory().location.pathname;

  useEffect(() => {
    if(authRedirectPath !== path){
      onSetAuthRedirect(path)
    }
  }, [onSetAuthRedirect, authRedirectPath, path])

  let loading = '';
  if(loadingNotif){
    loading = <Loading />
  }

  const [modalState, setModalState] = useState({
    maxWidth: 'sm',
    title: '',
    open: false,
  })

  const [form, setForm] = useState('');

  const show = (notif) => {
    setModalState({
      maxWidth: 'sm',
      title: 'Pengingat!',
      open: true,
    });
    setForm(<NotificationShow notif={notif} closed={() => closedModalDialog()}/>)
    console.log(notif);
  }

  const closedModalDialog = () => {
    setModalState({
      maxWidth: 'sm',
      title: '',
      open: false,
    });
    setForm('');
  }

  return (
    <Fragment>
      <div className={classes.contentSpace}>
        {loading}

        <Modal
          maxWidth={modalState.maxWidth}
          open={modalState.open}
          title={modalState.title}
          onCloseModal={closedModalDialog}
          contentModal={form}
        />

        <div className={classes.row}>
          <Grid container >
            <Grid item lg={4} md={4} sm={12} xs={12} >
              <Typography variant="h4">Notifications</Typography>
            </Grid>
          </Grid>
        </div>

        <div className={classes.row}>
          <Grid container >
            <Grid item lg={12} md={12} sm={12} xs={12} >
              <NotificationList show={(notif) => show(notif)}/>
            </Grid>
          </Grid>
        </div>
      </div>
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    loadingNotif: state.notification.loading,
    // changing: state.category.changing,
    authRedirectPath: state.auth.authRedirectPath,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSetAuthRedirect: (path) => dispatch(actions.setAuthRedirectPath(path)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);