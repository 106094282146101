import { IconButton, TableCell, TableRow, Tooltip } from '@material-ui/core'
import { Search as DetailIcon, Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import React, { Fragment } from 'react'

const Bank = props => {
  const { bank, index } = props
  return (
    <Fragment>
      <TableRow key={bank.id_jury}>
        <TableCell>
          {index + 1}
        </TableCell>
        <TableCell>
          {bank.name}
        </TableCell>
        <TableCell style={{ width: 160 }} align="right">
          {bank.username}
        </TableCell>
        <TableCell style={{ width: 160 }} align="right">
          {bank.phone}
        </TableCell>
        <TableCell style={{ width: 150 }} align="right">
          <Tooltip title="Detail Bank">
            <IconButton aria-label="detail" onClick={props.detailed}>
              <DetailIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit Bank">
            <IconButton aria-label="edit" onClick={props.edited}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Hapus Bank">
            <IconButton aria-label="delete" onClick={props.deleted}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default Bank
