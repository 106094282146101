import React, { Fragment, useEffect, useState } from 'react';
import { Paper, Table, TableCell, TableContainer, TableHead, TableRow, Typography, makeStyles, TableBody, TableFooter, TablePagination, Grid, Fab } from '@material-ui/core';

import TablePaginationsArrayActions from '../../../../../UI/Table/TablePaginationArrayActions/TablePaginationsArrayActions';
import TableBodyReminder from './TableBodyReminder/TableBodyReminder';
import { connect } from 'react-redux';
import * as actions from '../../../../../../store/actions/index';
import { AddCircle } from '@material-ui/icons';

const useStyle = makeStyles(theme => ({
  table: {
    minWidth: 500
  },
  topTable: {
    padding: 16,
    backgroundColor: '#FAFAFA'
  },
  sideRight: {
    textAlign: 'right'
  },
  btnAdd: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    },
    color: '#FFFFFF',
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
}))

const columns = [
  // { id: 'nama_pengingat', label: 'Nama Pengingat' },
  { id: 'deskripsi', label: 'Deskripsi' },
  { id: 'tanggal', label: 'Tanggal' },
  { id: 'waktu spesifik', label: 'Waktu Spesifik'},
  { id: 'status_perulangan', label: 'Status Perulangan' },
  { id: 'action', label: 'Action', align:"right"}
]

const ListReminder = props => {

  const { assetDetail, changingReminderAset, onCloseAlert, onDialogBox } = props;

  useEffect(() => {
    backPageStart();
  }, [changingReminderAset])
  
  const classes = useStyle();
  let tableBody = '';
  
  const backPageStart = () => {
    setPage(0)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(5)
  const countRows = assetDetail.reminder.length
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, countRows - page * rowsPerPage);

  if(assetDetail.reminder.length > 0){
    tableBody = (
      <Fragment>
        <TableBody>
          {
          // statusAsset.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((status) => (
            assetDetail.reminder.map((reminder) => (
            <TableBodyReminder
              reminder={reminder}
              key={reminder.id_reminder}
              edited={() => props.editReminderForm(reminder)}
              deleted={() => {
                onCloseAlert()
                onDialogBox('Yakin ingin menghapus data Remainder? ', reminder.body, reminder.id_reminder, actions.deleteReminderAsset)
              }}
            />
          )).reverse().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={8} />
            </TableRow>
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={5}
              colSpan={8}
              count={countRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              ActionsComponent={TablePaginationsArrayActions}
            />
          </TableRow>
        </TableFooter>
      </Fragment>
    )
  }


  return (
    <TableContainer component={Paper}>
      <div className={classes.topTable}>
        <Grid container spacing={2}>        
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Typography variant="h4">History Reminder Asset</Typography>
          </Grid>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <div className={classes.sideRight}>
              <Fab variant="extended" className={classes.btnAdd} onClick={() => props.addReminderForm()}>
                <AddCircle className={classes.extendedIcon} />
                Reminder
              </Fab>
            </div>
          </Grid>      
        </Grid>
      </div>
      <hr/>
      <Table className={classes.table} aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {tableBody}
      </Table>
    </TableContainer>
  )
}

const mapStateToProps = state => {
  return {
    changingReminderAset: state.assetDetail.changingReminderAset
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onCloseAlert: () => dispatch(actions.removeAlert()),
    onDialogBox: (message, data, id, action) => dispatch(actions.setDialogBox(message, data, id, action)),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(ListReminder);