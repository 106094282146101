import { Fab, Grid, makeStyles, Typography } from '@material-ui/core'
import { AddCircle } from '@material-ui/icons'
import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import Loading from '../../components/UI/Loading/CircularProgress/CircularProgress'
import Modal from '../../components/UI/Modal/ScrollDialog/ScrollDialog'

import * as actions from '../../store/actions/index'

import DetailAset from '../../components/View/AsetWakaf/DetailAset/DetailAset';
import palette from '../../theme/palette'
import ListAsetWakaf from '../../components/View/AsetWakaf/ListAsetWakaf/ListAsetWakaf';
import AddAsetWakaf from '../../components/View/AsetWakaf/AddAsetWakaf/AddAsetWakaf';
import EditAsetWakaf from '../../components/View/AsetWakaf/EditAsetWakaf/EditAsetWakaf';

const useStyles = makeStyles(theme => ({
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  contentSpace: {
    padding: '10px 32px'
    // backgroundColor: '#EEEEEE'
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(2)
  },
  sideRight: {
    textAlign: 'right'
  },
  btnAdd: {
    backgroundColor: '#FF9300',
    '&:hover': {
      backgroundColor: '#FF9300'
    },
    color: '#FFFFFF',
    // width: '100%',
    justifyContent: 'left'
  },
  btnDownload: {
    backgroundColor: palette.primary.main,
    '&:hover': {
      backgroundColor: palette.primary.dark
    },
    color: '#FFFFFF',
    width: '100%',
    justifyContent: 'left'
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'auto'
  // marginTop: theme.spacing(2)
  },
  searchSelectRoot: {
    // padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'auto'
  // marginTop: theme.spacing(2)
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10,
    backgroundColor: '#FF9300',
    '&:hover': {
      backgroundColor: '#FF9300'
    },
    color: '#FFFFFF',
    borderRadius: 0
  },
  divider: {
    height: 28,
    margin: 4
  },
  table: {
    minWidth: 500
  },
  formRoot: {
    // border: '1px groove #000',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2, 4, 3)
  },
  imageList: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  buttonDelete: {
    backgroundColor: 'rgb(220, 0, 78)',
    '&:hover': {
      backgroundColor: 'rgb(200, 0, 58)'
    },
    color: '#FFFFFF'
  }
}))
 
const AsetWakafs = props => {
  const classes = useStyles()

  const {onSetAuthRedirect, changing,loading,assets,changingUpdate}= props
  const path = window.location.pathname
  
  useEffect(() => {
    onSetAuthRedirect(path)
    // onFetchAsset(1, token, formSearch)
    closedModalDialog()
  }, [onSetAuthRedirect,path,changing])

  useEffect(() => {
    closedModalDialog();
  },[changingUpdate])

  let loadings = (loading ? <Loading /> : '');

  const [Form, setForm] = useState('');
  const [modalState, setModalState] = useState({
    open: false,
    title: '',
    maxWidth: 'sm',
  });

  const add = () => {
    setModalState({
      open: true,
      title: 'Tambah Aset',
      maxWidth: 'xl'
    });
    setForm(<AddAsetWakaf closedModalDialog={() => closedModalDialog()}/>)
    // setCurrentPage(0);
  }

  const edit = (asset) => {
    // setCurrentPage(assets.current_page);
    setModalState({
      open: true,
      title: 'Edit Aset',
      maxWidth: 'xl'
    });
    setForm(<EditAsetWakaf asset={asset} page={assets.current_page} closedModalDialog={() => closedModalDialog()} />)
  }

  const show = (asset) => {
    setModalState({
      open: true,
      title: 'Detail Aset',
      maxWidth: 'xl'
    });
    setForm(<DetailAset assetDetail={asset}/>)
  };

  const closedModalDialog = () => {
    setModalState({
      maxWidth: 'sm',
      title: '',
      open: false,
    });
    setForm('');
  }

  return (
    
    <Fragment>
      <div className={classes.contentSpace}>
        {/* {errorMessage} */}
        <Modal
          maxWidth={modalState.maxWidth}
          open={modalState.open}
          title={modalState.title}
          onCloseModal={closedModalDialog}
          contentModal={Form}
        />

        <div className={classes.row}>
          <Grid container >
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <Typography variant="h4">Manajemen Aset</Typography>
            </Grid>
            <Grid item lg={9} md={9} sm={12} xs={12}>
              <div className={classes.sideRight}>
                <Grid container >
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container >
                      <Grid item lg={3} md={3} sm={false} xs={false}/>
                      <Grid item lg={9} md={9} sm={12} xs={12}>
                        <Grid container >
                          <Grid item lg={12} md={12} sm={false} xs={false}/>
                            <Grid container spacing={2}>
                              <Grid item lg={8} md={8} sm={false} xs={12}/>
                              <Grid item lg={4} md={4} sm={12} xs={12}>
                                <Fab variant="extended" className={classes.btnAdd} onClick={add}>
                                  <AddCircle className={classes.extendedIcon}/>
                                  Aset
                                </Fab>
                              </Grid>

                            </Grid>
                          </Grid>
                        </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>

        {/* list */}
        {loadings}
        <ListAsetWakaf show={(asset) => show(asset)} edit={(asset) => edit(asset)}/>
        
      </div>
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    loading: state.asset.loading,
    changing: state.asset.changing,
    changingUpdate: state.asset.changingUpdate,
    assets: state.asset.assets,
    // token: state.auth.token,
    // errorImage: state.asset.errorImage,
    // errorDocument: state.asset.errorDocument,
    // error: state.bank.error
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSetAuthRedirect: (path) => dispatch(actions.setAuthRedirectPath(path)),
    // onFetchAsset: (page, token, formSearch) => dispatch(actions.fetchAsetWakafs(page, token, formSearch)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AsetWakafs)
