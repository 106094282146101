import React, { Fragment } from 'react'
import { Chip, IconButton, makeStyles, TableCell, TableRow, Tooltip } from '@material-ui/core'
import { Edit as EditIcon, Settings } from '@material-ui/icons'
// import NumberFormat from 'react-number-format';
import { NavLink } from 'react-router-dom';
import palette from '../../../../../theme/palette'

const useStyles = makeStyles(theme => ({
  chipError: {
    color : palette.error.main,
    border: '1px solid'+palette.error.main,
    width: 150
  },
  chipSuccess: {
    color : palette.success.main,
    border: '1px solid'+palette.success.main,
    width: 150
  },
  chipWarning: {
    color : palette.warning.main,
    border: '1px solid'+palette.warning.main,
    width: 150
  },
  chipBlur: {
    color : '#707070',
    border: '1px solid #707070',
    width: 150
  },
  chipBlue: {
    color : '#0277BD',
    border: '1px solid #0277BD',
    width: 150
  },
  chipBlack: {
    color: '#231E2E',
    border: '1px solid #231E2E',
    width: 150
  },
  manajemenAset: {
    color: theme.palette.warning.light,
  }, 
  btnEdit: {
    color: theme.palette.success.main,
  }
}))

const AsetWakaf = props => {
  const { asset } = props
  const classes = useStyles();

  let statusAset = '-';
  if(asset.status.length !== 0){
    if(asset.status[asset.status.length - 1].status === 0) {
      statusAset = <Chip variant="outlined" size="medium" className={classes.chipBlur} label="Tidak Aktif" />;
    }else if(asset.status[asset.status.length - 1].status === 1){
      statusAset = <Chip variant="outlined" size="medium" className={classes.chipSuccess} label="Aktif" />;
    }else if(asset.status[asset.status.length - 1].status === 2){
      statusAset = <Chip variant="outlined" size="medium" className={classes.chipWarning} label="Maintenance" />;
    }else if(asset.status[asset.status.length - 1].status === 3){
      statusAset = <Chip variant="outlined" size="medium"  className={classes.chipError} label="Rusak" />;
    }else if(asset.status[asset.status.length - 1].status === 4){
      statusAset = <Chip variant="outlined" size="medium" className={classes.chipBlue} label="Dijual" />;
    }else if(asset.status[asset.status.length - 1].status === 5){
      statusAset = <Chip variant="outlined" size="medium" className={classes.chipBlack} label="Hilang" />;
    }else{
      statusAset = '-';
    }
  }
  

  return (
    <Fragment>
      <TableRow key={asset.uuid}>
        <TableCell>
          {asset.name}
        </TableCell>
        <TableCell>
          {asset.category ? asset.category.name : null}
        </TableCell>
        <TableCell>
          {asset.type ? asset.type.name : null}
        </TableCell>
        <TableCell>
          {asset.wakif[0].name}
        </TableCell>
        <TableCell>
          {(asset.history.length !== 0) ? asset.history[asset.history.length - 1].name : ' '}
        </TableCell>
        <TableCell>
          {(asset.history.length !== 0) ? asset.history[asset.history.length - 1].entity.name : ' '}
        </TableCell>
        <TableCell>
          {statusAset}
        </TableCell>
        {/* <TableCell>
          <NumberFormat thousandSeparator={'.'} prefix={'Rp.'} decimalSeparator={','} displayType={'text'} value={asset.value}/>
        </TableCell> */}
        <TableCell align="right">
          <Tooltip title="Manajemen Aset">
            <NavLink to={'/manajemen-aset/' + asset.uuid}>
              <IconButton aria-label="manajemen">
                <Settings className={classes.manajemenAset} />
              </IconButton>
            </NavLink>
          </Tooltip>
          {/* <Tooltip title="Detail Aset">
            <IconButton aria-label="detail" onClick={props.detailed}>
              <DetailIcon />
            </IconButton>
          </Tooltip> */}
          <Tooltip title="Edit Aset">
            <IconButton aria-label="edit" onClick={props.edited}>
              <EditIcon className={classes.btnEdit}/>
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default AsetWakaf
