import * as actions from './actionTypes'
import axios from '../../axios-orders'
import { setAlert } from './alert'

export const fetchCategoryStart = () => {
  return {
    type: actions.FETCH_CATEGORY_START
  }
}

export const fetchCategorySuccess = (categories) => {
  return {
    type: actions.FETCH_CATEGORY_SUCCESS,
    categories: categories
  }
}

export const fetchCategoryFail = (error) => {
  return {
    type: actions.FETCH_CATEGORY_FAIL,
    error: error
  }
}

export const fetchCategory = (token) => {
  return dispatch => {
    dispatch(fetchCategoryStart())
    axios.get('assets/category', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        // console.log(res.data.data)
        dispatch(fetchCategorySuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchCategoryFail(err))
      })
  }
}

export const storeCategoryStart = () => {
  return {
    type: actions.STORE_CATEGORY_START
  }
}

export const storeCategorySuccess = (category) => {
  return {
    type: actions.STORE_CATEGORY_SUCCESS
  }
}

export const storeCategoryFail = (error) => {
  return {
    type: actions.STORE_CATEGORY_FAIL,
    error: error
  }
}

export const storeCategory = (storeData, token) => {
  return dispatch => {
    dispatch(storeCategoryStart())
    axios.post('assets/category', storeData,{
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        // console.log(res.data.data)
        dispatch(storeCategorySuccess(res.data.data))
        dispatch(setAlert(res.data.msg_str, 'success'))
      })
      .catch(err => {
        dispatch(storeCategoryFail(err.response.data.throwable))
        dispatch(setAlert(err.response.data.msg_str, 'error'))
      })
  }
}

export const updateCategoryStart = () => {
  return {
    type: actions.UPDATE_CATEGORY_START
  }
}

export const updateCategorySuccess = (category) => {
  return {
    type: actions.UPDATE_CATEGORY_SUCCESS
  }
}

export const updateCategoryFail = (error) => {
  return {
    type: actions.UPDATE_CATEGORY_FAIL,
    error: error
  }
}

export const updateCategory = (id, storeData, token) => {
  return dispatch => {
    dispatch(updateCategoryStart())
    axios.put('assets/category/' + id, storeData,{
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        // console.log(res.data.data)
        dispatch(updateCategorySuccess(res.data.data))
        dispatch(setAlert(res.data.msg_str, 'success'))
      })
      .catch(err => {
        dispatch(updateCategoryFail(err.response.data.throwable))
        dispatch(setAlert(err.response.data.msg_str, 'error'))
      })
  }
}

export const clearErrorCategory = () => {
  return {
    type: actions.CLEAR_ERROR_CATEGORY
  }
}