import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  entities: {},
  changing: 0,
  
  loading: false,
  error: {},
}

const fetchEntityStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const fetchEntitySuccess = (state, action) => {
  return updateObject(state, {
    entities: action.entities,
    loading: false
  })
}

const fetchEntityFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const storeEntityStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const storeEntitySuccess = (state, action) => {
  return updateObject(state, {
    changing: state.changing+1,
    loading: false
  })
}

const storeEntityFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const updateEntityStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const updateEntitySuccess = (state, action) => {
  return updateObject(state, {
    changing: state.changing+1,
    loading: false
  })
}

const updateEntityFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const clearErrorEntity = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: {}
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ENTITY_START: return fetchEntityStart(state, action)
    case actionTypes.FETCH_ENTITY_SUCCESS: return fetchEntitySuccess(state, action)
    case actionTypes.FETCH_ENTITY_FAIL: return fetchEntityFail(state, action)
    case actionTypes.STORE_ENTITY_START: return storeEntityStart(state, action)
    case actionTypes.STORE_ENTITY_SUCCESS: return storeEntitySuccess(state, action)
    case actionTypes.STORE_ENTITY_FAIL: return storeEntityFail(state, action)
    case actionTypes.UPDATE_ENTITY_START: return updateEntityStart(state, action)
    case actionTypes.UPDATE_ENTITY_SUCCESS: return updateEntitySuccess(state, action)
    case actionTypes.UPDATE_ENTITY_FAIL: return updateEntityFail(state, action)

    case actionTypes.CLEAR_ERROR_ENTITY: return clearErrorEntity(state, action)

    default: return state
  }
}

export default reducer