import * as actions from './actionTypes'
import axios from '../../axios-orders'
import { setAlert } from './alert'

export const fetchTypeAssetsStart = () => {
  return {
    type: actions.FETCH_TYPE_ASSET_START
  }
}

export const fetchTypeAssetsSuccess = (typeAssets) => {
  return {
    type: actions.FETCH_TYPE_ASSET_SUCCESS,
    typeAssets: typeAssets
  }
}

export const fetchTypeAssetsFail = (error) => {
  return {
    type: actions.FETCH_TYPE_ASSET_FAIL,
    error: error
  }
}

export const fetchTypeAssets = (token) => {
  return dispatch => {
    dispatch(fetchTypeAssetsStart())
    axios.get('assets/type', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        // console.log(res.data.data)
        dispatch(fetchTypeAssetsSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchTypeAssetsFail(err))
      })
  }
}

export const storeTypeAssetStart = () => {
  return {
    type: actions.STORE_TYPE_ASSET_START
  }
}

export const storeTypeAssetSuccess = () => {
  return {
    type: actions.STORE_TYPE_ASSET_SUCCESS
  }
}

export const storeTypeAssetFail = (error) => {
  return {
    type: actions.STORE_TYPE_ASSET_FAIL,
    error: error
  }
}

export const storeTypeAsset = (storeData, token) => {
  return dispatch => {
    dispatch(storeTypeAssetStart());
    axios.post('assets/type', storeData,{
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        // console.log(res.data.data)
        dispatch(storeTypeAssetSuccess())
        dispatch(setAlert(res.data.msg_str, 'success'))
      })
      .catch(err => {
        dispatch(storeTypeAssetFail(err.response.data.msg_str))
        dispatch(setAlert(err.response.data.msg_str, 'error'))
      })
  }
}

export const updateTypeAssetStart = () => {
  return {
    type: actions.UPDATE_TYPE_ASSET_START
  }
}

export const updateTypeAssetSuccess = () => {
  return {
    type: actions.UPDATE_TYPE_ASSET_SUCCESS
  }
}

export const updateTypeAssetFail = (error) => {
  return {
    type: actions.UPDATE_TYPE_ASSET_FAIL,
    error: error
  }
}

export const updateTypeAsset = (id, storeData, token) => {
  return dispatch => {
    dispatch(updateTypeAssetStart());
    axios.put('assets/type' + id, storeData,{
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        // console.log(res.data.data)
        dispatch(updateTypeAssetSuccess())
        dispatch(setAlert(res.data.msg_str, 'success'))
      })
      .catch(err => {
        dispatch(updateTypeAssetFail(err.response.data.msg_str))
        dispatch(setAlert(err.response.data.msg_str, 'error'))
      })
  }
}

export const clearErrorTypeAsset = () => {
  return {
    type: actions.CLEAR_ERROR_TYPE_ASSET
  }
}