import * as actions from './actionTypes'
import axios from '../../axios-orders'

export const fetchBanksStart = () => {
  return {
    type: actions.FETCH_BANK_START
  }
}

export const fetchBanksSuccess = (banks, links, meta) => {
  return {
    type: actions.FETCH_BANK_SUCCESS,
    banks: {
      banks,
      links,
      meta
    }
  }
}

export const fetchBanksFail = (error) => {
  return {
    type: actions.FETCH_BANK_FAIL,
    error: error
  }
}

export const fetchBanks = (page, token) => {
  return dispatch => {
    dispatch(fetchBanksStart())
    axios.get('eo/jury?page=' + page, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `bearer ${token}`
      }
    })
      .then(res => {
        console.log('[res]', res.data)
        const fetchedBanks = []
        for (const key in res.data.data) {
          fetchedBanks.push({
            ...res.data.data[key]
            // id: key
          })
        }
        // console.log('[link]', res.data.links, 'meta', res.data.meta.last_page);
        dispatch(fetchBanksSuccess(fetchedBanks, res.data.links, res.data.meta))
      })
      .catch(err => {
        dispatch(fetchBanksFail(err))
      })
  }
}

export const storeJurySuccess = (id, storeData) => {
  return {
    type: actions.STORE_JURY_SUCCESS,
    storeDataId: id,
    storeData: storeData
  }
}

export const storeJuryFail = (error) => {
  return {
    type: actions.STORE_JURY_FAIL,
    error: error
  }
}

export const storeJuryStart = () => {
  return {
    type: actions.STORE_JURY_START
  }
}

export const updateJuryStart = () => {
  return {
    type: actions.UPDATE_JURY_START
  }
}

export const updateJurySuccess = (id, storeData) => {
  console.log('redux', storeData)
  return {
    type: actions.UPDATE_JURY_SUCCESS,
    storeDataId: id,
    storeData: storeData
  }
}

export const updateJuryFail = (error) => {
  return {
    type: actions.UPDATE_JURY_FAIL,
    error: error
  }
}

export const updateJury = (id, storeData, token) => {
  return dispatch => {
    dispatch(updateJuryStart())
    axios.patch('eo/jury/' + id, storeData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `bearer ${token}`
      }
    })
      .then(response => {
        console.log(response)
        dispatch(updateJurySuccess(id, storeData))
      })
      .catch(error => {
        dispatch(updateJuryFail(error))
      })
  }
}

export const storeJury = (storeData, token) => {
  return dispatch => {
    dispatch(storeJuryStart())
    const bodyFormData = new FormData()
    bodyFormData.set('name', storeData.name)
    bodyFormData.set('username', storeData.username)
    bodyFormData.set('password', storeData.password)
    bodyFormData.set('phone', storeData.phone)
    bodyFormData.set('image', storeData.image)

    axios({
      method: 'post',
      url: 'eo/jury',
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        Authorization: `bearer ${token}`
      }
    })
      .then(response => {
      // console.log(response.data.jury.id_jury);
        dispatch(storeJurySuccess(response.data.jury.id_jury, response.data.jury))
      })
      .catch(error => {
        console.log(error)
        dispatch(storeJuryFail(error.response.data.message))
      })
  }
}

export const deleteJurySuccess = (id) => {
  // console.log(id)
  return {
    type: actions.DELETE_JURY_SUCCESS,
    juryId: id
    // index: index
  }
}

export const deleteJuryFail = (error) => {
  return {
    type: actions.DELETE_JURY_FAIL,
    error: error
  }
}

export const deleteJuryStart = () => {
  return {
    type: actions.DELETE_JURY_START
  }
}

export const deleteJury = (juryId, token) => {
  return dispatch => {
    dispatch(deleteJuryStart())
    axios.delete('eo/jury/' + juryId, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `bearer ${token}`
      }
    })
      .then(response => {
        // console.log(response)
        dispatch(deleteJurySuccess(juryId))
      })
      .catch(error => {
        dispatch(deleteJuryFail(error))
      })
  }
}
