import { Grid, Paper, Typography, makeStyles, TextField, Button } from '@material-ui/core';
import React from 'react';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';

import * as actions from '../../../../store/actions/index';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  formRoot: {
    // border: '1px groove #000',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2, 4, 3)
  }
}));

const schema = yup.object().shape({
});

const Form = props => {
  const classes = useStyles();
  const {token, onAuthChangePassword} = props;

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  })

  console.log(token)
  const onSubmit = data => {
    // console.log(data)
    onAuthChangePassword(data, token)

    // data.input_date = moment.utc().format('YYYY-MM-DD HH:mm:ss');
    // const jam_utc = moment.utc().format('HH:mm:ss');
    // data.return_date = moment.utc(data.return_date+' '+jam_utc).format('YYYY-MM-DD HH:mm:ss');

    // data.emp_id = '0';
    // props.onGiveAsset(data, token);
    // console.log(data);
    // closedModalDialog();
  }

  // console.log(props.token)
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Paper className={classes.formRoot}>
            <Typography>Ubah Password</Typography>
            <div className={classes.row}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="current"
                    label="Password Saat ini"
                    type="password"
                    inputRef={register}
                    error={!!errors.password}
                    helperText={errors.password && errors.password.message}
                    className={classes.textField}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={classes.row}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="new"
                    label="Password Baru"
                    type="password"
                    inputRef={register}
                    error={!!errors.password}
                    helperText={errors.password && errors.password.message}
                    className={classes.textField}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={classes.row}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="repeat"
                    label="Konfirmasi Password"
                    type="password"
                    inputRef={register}
                    error={!!errors.password}
                    helperText={errors.password && errors.password.message}
                    className={classes.textField}
                  />
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item lg={8} md={8} sm={12} xs={12}>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Button type="submit" variant="contained" fullWidth onClick={props.closedModalDialog} disableElevation>
                Batal
              </Button>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Button type="submit" variant="contained" style={{ backgroundColor: '#FF9300', color: '#FFFFFF' }} fullWidth disableElevation>
                Simpan
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onAuthChangePassword: (storeData, token) => dispatch(actions.authChangePassword(storeData, token))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form);