import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  categories: {},
  loading: false,
  error: {},
  changing: 0,
}

const fetchCategoryStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const fetchCategorySuccess = (state, action) => {
  return updateObject(state, {
    categories: action.categories,
    loading: false
  })
}

const fetchCategoryFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const storeCategoryStart = (state, action) => {
  return updateObject(state, {
    loading: true
  })
};

const storeCategorySuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    changing: state.changing+1
  })
};

const storeCategoryFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error
  })
};

const updateCategoryStart = (state, action) => {
  return updateObject(state, {
    loading: true
  })
};

const updateCategorySuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    changing: state.changing+1
  })
};

const updateCategoryFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error
  })
};

const clearErrorCategory = (state, action) => {
  return updateObject(state, {
    error: {}
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CATEGORY_START: return fetchCategoryStart(state, action)
    case actionTypes.FETCH_CATEGORY_SUCCESS: return fetchCategorySuccess(state, action)
    case actionTypes.FETCH_CATEGORY_FAIL: return fetchCategoryFail(state, action)
    case actionTypes.STORE_CATEGORY_START: return storeCategoryStart(state, action)
    case actionTypes.STORE_CATEGORY_SUCCESS: return storeCategorySuccess(state, action)
    case actionTypes.STORE_CATEGORY_FAIL: return storeCategoryFail(state, action)
    case actionTypes.UPDATE_CATEGORY_START: return updateCategoryStart(state, action)
    case actionTypes.UPDATE_CATEGORY_SUCCESS: return updateCategorySuccess(state, action)
    case actionTypes.UPDATE_CATEGORY_FAIL: return updateCategoryFail(state, action)
    case actionTypes.CLEAR_ERROR_CATEGORY: return clearErrorCategory(state, action)
    default: return state
  }
}

export default reducer