import { IconButton, TableCell, TableRow, Tooltip } from '@material-ui/core';
import React from 'react';
import moment from  'moment';
import { Search } from '@material-ui/icons';

const Notification = props => {
  const {notif} = props;
  const tgl = moment(notif.created_at).format('DD/MM/YYYY HH:mm:ss');
  return (
    <TableRow key={notif.id_notification}>
      <TableCell>
        {notif.title}
      </TableCell>
      <TableCell>
        {notif.body}
      </TableCell>
      <TableCell>
        {tgl}
      </TableCell>
      <TableCell style={{ width: 150 }} align="right">
        <Tooltip title="Show Notification">
          <IconButton aria-label="edit" onClick={props.show}>
            <Search />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

export default Notification;