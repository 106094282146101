import React, { Fragment, useEffect, useState } from 'react'
import {
  Button, Divider, Fab, Grid, Hidden, IconButton, InputBase, makeStyles, Paper, Table, TableBody, TableCell, TableContainer,
  TableFooter, TableHead, TablePagination, TableRow, TextField, Typography
} from '@material-ui/core'
import { connect } from 'react-redux'
import * as actions from '../../../store/actions/index'

import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'

import Dropzone from '../../../components/UI/Dropzone/Dropzone'
import Bank from '../../../components/View/Bank/Bank'
import Loading from '../../../components/UI/Loading/CircularProgress/CircularProgress'
import TablePaginationActions from '../../../components/UI/Table/TablePagination/TablePaginationActions'
import { AddCircle, Search as SearchIcon } from '@material-ui/icons'
import Modal from '../../../components/UI/Modal/Modal'

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 500
  },
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  btnAdd: {
    backgroundColor: '#0277BD',
    '&:hover': {
      backgroundColor: '#2092D6'
    },
    color: '#FFFFFF'
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'auto'
  // marginTop: theme.spacing(2)
  },
  formRoot: {
    border: '1px groove #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(2)
  },
  contentSpace: {
    padding: theme.spacing(4)
  },
  bgColor: {
    backgroundColor: '#BCE0FD',
    height: '312px',
    position: 'absolute'
  }
}))

const schema = yup.object().shape({
  name: yup.string().required(),
  username: yup.string().required(),
  password: yup.string().min(6).nullable(),
  phone: yup.string().required(),
  id_jury: yup.string().nullable()
})

const Banks = props => {
  const { onSetAuthRedirect, token, onFetchBank, page } = props
  const path = window.location.pathname

  useEffect(() => {
    onSetAuthRedirect(path)
    onFetchBank(page, token)
  }, [onSetAuthRedirect, path, token, onFetchBank, page])

  const classes = useStyles()

  // Handle Change Banner
  const [image, setImage] = useState([])
  // const [base64, setB64] = useState()

  const handleChangeBanner = event => {
    setImage(event[0])
    // console.log(event[0])
    // const reader = new FileReader()
    // reader.readAsDataURL(event[0])
    // reader.onload = function () {
    //   setB64(reader.result)
    //   // console.log(reader.result);
    // }
  }
  // console.log(image);
  // End Handle image

  const handleChangePage = (event, newPage) => {
    console.log('newpage ', newPage)
    onFetchBank(newPage, token)
  }

  const [open, setOpen] = useState(false)
  const [actionState, setActionState] = useState('Add')

  const [formState, setFormState] = useState({
    id_jury: {
      value: ''
    },
    name: {
      value: ''
    },
    username: {
      value: ''
    },
    password: {
      value: null
    },
    phone: {
      value: ''
    }
  })

  const add = () => {
    setOpen(true)
    setActionState('Add')
  }

  const edit = (bank) => {
    setFormState((formState) => ({
      ...formState,
      id_jury: {
        ...formState.id_jury,
        value: bank.id_jury
      },
      name: {
        ...formState.name,
        value: bank.name
      },
      username: {
        ...formState.username,
        value: bank.username
      },
      password: {
        ...formState.password,
        value: bank.password
      },
      phone: {
        ...formState.phone,
        value: bank.phone
      }
    }))
    setOpen(true)
    setActionState('Edit')
  }

  const closedModal = () => {
    setFormState((formState) => ({
      ...formState,
      id_jury: {
        value: ''
      },
      name: {
        value: ''
      },
      username: {
        value: ''
      },
      password: {
        value: null
      },
      phone: {
        value: ''
      }
    }))
    setOpen(false)
  }

  const handleChange = (event) => {
    const target = event.target.name
    event.persist()

    setFormState((formState) => ({
      ...formState,
      [target]: {
        ...formState.jk,
        value: event.target.value
      }
    }))
  }

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  })

  const onSubmit = data => {
    data.image = image
    // console.log(data.image);
    props.onStoreJury(data, token)
    // console.log(data);
    closedModal()
    // if(props.error){
    //   props.onAlert(props.error, "error");
    // }else {
    //   props.onAlert("sukses", "success");
    // }
  }

  const editSubmit = data => {
    props.onUpdateJury(data.id_jury, data, token)
    closedModal()
  }

  let Form = null
  Form = (
    <form onSubmit={actionState === 'Add' ? handleSubmit(onSubmit) : handleSubmit(editSubmit)}>
      {actionState === 'Edit' ? (
        <TextField
          variant="standard"
          name="id_jury"
          inputRef={register}
          error={!!errors.id_jury}
          helperText={errors.id_jury && errors.id_jury.message}
          defaultValue={formState.id_jury.value}
          type="hidden"
          onChange={handleChange}
        />
      ) : null}

      <div className={classes.row}>
        <Grid container spacing={2}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextField
              label="name"
              variant="outlined"
              name="name"
              inputRef={register}
              error={!!errors.name}
              helperText={errors.name && errors.name.message}
              fullWidth
              defaultValue={formState.name.value}
              onChange={handleChange}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextField
              label="username"
              variant="outlined"
              name="username"
              inputRef={register}
              error={!!errors.username}
              helperText={errors.username && errors.username.message}
              fullWidth
              defaultValue={formState.username.value}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              label="password"
              variant="outlined"
              name="password"
              type="password"
              inputRef={register}
              error={!!errors.password}
              helperText={errors.password && errors.password.message}
              fullWidth
              defaultValue={formState.password.value}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              label="phone"
              variant="outlined"
              name="phone"
              inputRef={register}
              error={!!errors.phone}
              helperText={errors.phone && errors.phone.message}
              fullWidth
              defaultValue={formState.phone.value}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Dropzone value={image} handleChangeBanner={handleChangeBanner} />
          </Grid>
        </Grid>
      </div>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Button type="submit" variant="contained" color="primary" fullWidth disableElevation>
            {actionState}
          </Button>
        </Grid>
      </Grid>
    </form>
  )

  /**
   * Table
   */
  // colum table
  const columns = [
    { id: 'no', label: 'No', width: 10 },
    { id: 'name', label: 'Name', align: 'left', minWidth: 160 },
    { id: 'username', label: 'Username', align: 'right', minWidth: 160 },
    { id: 'phone', label: 'Phone', align: 'right', minWidth: 160 },
    { id: 'action', label: 'Action', align: 'right', minWidth: 20 }
  ]

  let loading = null
  if (props.loading) {
    loading = <Loading />
  }

  let tableBody = null
  if (!props.loading) {
    const page = props.banks.meta.current_page - 1
    const rowsPerPage = props.banks.meta.per_page
    const countRows = props.banks.meta.total
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, countRows - page * rowsPerPage)

    tableBody = (
      <Fragment>
        <TableBody>
          {props.banks.banks.map((bank, index) => (
            <Bank
              bank={bank}
              key={bank.id_jury}
              index={index}
              detailed={() => edit(bank)}
              edited={() => edit(bank)}
              deleted={() => {
                props.onCloseAlert()
                props.onDialogBox('yakin hapus data jury?', bank, actions.deleteJury)
              }}
            />
          ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[props.banks.meta.per_page]}
              colSpan={5}
              count={countRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <div className={classes.contentSpace}>
        <div className={classes.bgColor}></div>
        <div className={classes.row}>
          <Grid
            container
            spacing={2}
          >
            <Grid item>
              <Typography variant="h4">Bank</Typography>
            </Grid>
          </Grid>
        </div>
        <div className={classes.row}>
          <Grid
            container
            spacing={2}
            justify='space-between'
          >

            {/* modal */}
            <Modal
              open={open}
              modalClosed={closedModal}
            >
              <h2 id="modal-title">bank</h2>
              <Paper className={classes.formRoot}>
                <div id="modal-body">
                  {Form}
                </div>
              </Paper>
            </Modal>

            <Hidden only={['xs', 'sm']}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Fab variant="extended" className={classes.btnAdd} onClick={add}>
                  <AddCircle className={classes.extendedIcon} />
                  Tambah Bank
                </Fab>
              </Grid>
            </Hidden>
            <Hidden only={['md', 'lg', 'xl']}>
              <Fab color="primary" aria-label="add" className={classes.fab}>
                <AddCircle/>
              </Fab>
            </Hidden>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Paper component="form" className={classes.searchRoot}>
                <InputBase
                  className={classes.input}
                  name="pesan"
                  placeholder="Cari Bank"
                  inputProps={{ 'aria-label': 'Cari Bank' }}
                />
                <Divider className={classes.divider} orientation="vertical" />
                <IconButton type="submit" className={classes.iconButton} aria-label="search">
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Grid>
          </Grid>
        </div>
        <Grid
          lg={12}
          sm={12}
          xl={12}
          xs={12}
          item
        >
          {loading}
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {tableBody}
            </Table>
          </TableContainer>
        </Grid>
      </div>
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    loading: state.bank.loading,
    token: state.auth.token,
    banks: state.bank.banks,
    page: state.bank.banks.meta.current_page
    // error: state.bank.error
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSetAuthRedirect: (path) => dispatch(actions.setAuthRedirectPath(path)),
    onFetchBank: (page, token) => dispatch(actions.fetchBanks(page, token)),
    onStoreJury: (storeData, token) => dispatch(actions.storeJury(storeData, token)),
    onUpdateJury: (id, storeData, token) => dispatch(actions.updateJury(id, storeData, token)),
    onAlert: (msg, status) => dispatch(actions.setAlert(msg, status)),
    onDialogBox: (header, message, action) => dispatch(actions.setDialogBox(header, message, action)),
    onCloseAlert: () => dispatch(actions.removeAlert())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Banks)
